import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import {
  Alert,
  Collapse,
  Box,
  Link as MuiLink,
  Typography,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import {
  passwordRules,
  PasswordTextField,
  Page,
  AppleLogo,
  GoogleLogo,
  MicrosoftLogo,
} from '@evoko/components';
import { ErrorCode, useAuth, useReturnTo } from '@evoko/api';
import locale from '../../locale';
import { SignInButton } from '../../components/SignInButton';
import { PageLogoHeader } from '../../components/PageLogoHeader';
import PageContent from '../../components/PageContent';
import ProfileDetailsDialog from './components/ProfileDetailsDialog';
import { emailFormPattern } from '../../utils';
import { useEffect, useMemo } from 'react';
import { ApolloError } from '@apollo/client';

export type RegisterLocationState = {
  email?: string;
};

const LL = locale.pages.register;

export default function RegisterPage() {
  const location = useLocation();
  const locationState = location?.state as RegisterLocationState | undefined;
  const loginHint = locationState?.email;
  const signInState: RegisterLocationState = { email: loginHint };
  const { returnTo, returnToSearch } = useReturnTo();

  const { control, handleSubmit, formState, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: loginHint ?? '',
      password: '',
    },
  });
  const { isValid, isDirty } = formState;

  const {
    loading,
    completeProfile,
    completeProfileHints,
    error,
    clearError,
    signInWithEmail,
    signInWithGoogle,
    signInWithMicrosoft,
    signInWithApple,
    submitCompleteProfile,
    cancelCompleteProfile,
  } = useAuth();

  // Clear any auth error on unmount
  useEffect(() => {
    return () => clearError();
  }, [clearError]);

  const alertError = useMemo(() => {
    if (error?.type !== 'register-email' && error?.type !== 'register-oauth') {
      return;
    }
    if (error.content instanceof ApolloError) {
      for (const { extensions } of error.content.graphQLErrors) {
        switch (extensions?.code) {
          case ErrorCode.EmailNotAvailable: {
            if (error.type === 'register-email') {
              setError('email', { message: LL.form.email.errors.notAvailable });
              // return undefined since we don't want both an `Alert` box error
              // and a `TextField` error.
              return;
            }
          }
        }
      }
    }
    return LL.error;
  }, [error, setError]);

  const onSubmit = handleSubmit(async (data) => {
    await signInWithEmail({ ...data, returnTo, register: true });
  });

  return (
    <Page title={LL.header} loading={loading} minHeight="100%">
      <PageLogoHeader />
      <PageContent component="main" maxWidth={800}>
        <Typography variant="h1" align="center">
          {LL.header}
        </Typography>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
          <SignInButton
            fullWidth
            disabled={loading}
            onClick={() =>
              signInWithGoogle({ returnTo, loginHint, register: true })
            }
            startIcon={<GoogleLogo aria-hidden />}
          >
            {LL.googleButton}
          </SignInButton>
          <SignInButton
            fullWidth
            disabled={loading}
            onClick={() =>
              signInWithMicrosoft({ returnTo, loginHint, register: true })
            }
            startIcon={<MicrosoftLogo aria-hidden />}
          >
            {LL.microsoftButton}
          </SignInButton>
          <SignInButton
            fullWidth
            disabled={loading}
            onClick={() => signInWithApple({ returnTo, register: true })}
            startIcon={<AppleLogo aria-hidden />}
          >
            {LL.appleButton}
          </SignInButton>
        </Box>
        <Divider>{LL.divider}</Divider>
        <Box component="form" onSubmit={onSubmit}>
          <Box display={{ xs: 'block', sm: 'flex' }} gap={2}>
            <Box flex="1">
              <Controller
                name="email"
                control={control}
                rules={emailFormPattern}
                render={({
                  field,
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="email"
                    label={LL.form.email.label}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={!!error && (isTouched || isSubmitted)}
                    helperText={
                      ((isTouched || isSubmitted) && error?.message) || ' '
                    }
                  />
                )}
              />
              <Controller
                name="name"
                control={control}
                rules={{ required: LL.form.name.required }}
                render={({
                  field,
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    label={LL.form.name.label}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={!!error && (isTouched || isSubmitted)}
                    helperText={
                      ((isTouched || isSubmitted) && error?.message) || ' '
                    }
                  />
                )}
              />
            </Box>
            <Box flex="1">
              <Controller
                name="password"
                control={control}
                rules={{
                  ...passwordRules,
                  required: LL.form.password.required,
                }}
                render={({
                  field,
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <PasswordTextField
                    {...field}
                    sx={{ mb: { xs: 1, sm: 0 } }}
                    label={LL.form.password.label}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={!!error && (isTouched || isSubmitted)}
                    helperText={
                      ((isTouched || isSubmitted) && error?.message) || ' '
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={350}
            mx="auto"
            mt={1}
            gap={2}
          >
            <Collapse in={!!alertError} unmountOnExit>
              <Alert severity="error">{alertError}</Alert>
            </Collapse>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!isValid || !isDirty || loading}
              type="submit"
              fullWidth
            >
              {LL.form.submitButton}
            </Button>
          </Box>
        </Box>
        <Typography variant="body2" align="center">
          {LL.signInLink.text}{' '}
          <MuiLink
            color="inherit"
            component={Link}
            to={{ pathname: '/signin', search: returnToSearch?.toString() }}
            state={signInState}
          >
            {LL.signInLink.link}
          </MuiLink>
        </Typography>
      </PageContent>

      <ProfileDetailsDialog
        open={completeProfile}
        onCancel={cancelCompleteProfile}
        onSubmit={submitCompleteProfile}
        profileDetails={completeProfileHints}
      />
    </Page>
  );
}
