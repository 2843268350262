import {
  Navigate,
  Outlet,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  Box,
  Stack,
} from '@mui/material';
import {
  AuthProvider,
  ApolloProvider,
  useAuth,
  getPublicUrlWithReturnTo,
} from '@evoko/api';
import {
  LegalErrorHandler,
  SnackbarProvider,
  SystemMessageBanners,
} from '@evoko/components';
import { createTheme } from '@evoko/styles';
import { HelmetProvider } from 'react-helmet-async';

import ProfilePage from './pages/ProfilePage';
import SignInPage from './pages/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import RegisterPage from './pages/RegisterPage';
import InvitePage from './pages/InvitePage';
import LandingPage from './pages/LandingPage';
import ActivatePage from './pages/ActivatePage';
import SignOutPage from './pages/SingOutPage';

function RequireAuthenticated() {
  const { authenticated } = useAuth();
  if (!authenticated) {
    const currentUrl = new URL(window.location.href);
    const { pathname, search } = getPublicUrlWithReturnTo('signin', currentUrl);
    return <Navigate to={{ pathname, search }} replace />;
  }
  return <Outlet />;
}

function RequireUnauthenticated() {
  const { authenticated } = useAuth();
  return !authenticated ? <Outlet /> : <Navigate to="/profile" replace />;
}

function Layout() {
  return (
    <Box
      height="100vh"
      display="grid"
      gridTemplateRows="auto 1fr"
      gridTemplateAreas={`
        "banner"
        "page"
      `}
    >
      <Stack gridArea="banner">
        <SystemMessageBanners />
      </Stack>
      <Box gridArea="page" overflow="auto">
        <Outlet />
      </Box>
    </Box>
  );
}

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <LandingPage /> },
        { path: 'verify-email', element: <VerifyEmailPage /> },
        { path: 'invite', element: <InvitePage /> },
        { path: 'reset-password', element: <ResetPasswordPage /> },
        {
          element: <RequireAuthenticated />,
          children: [
            { path: 'profile/*', element: <ProfilePage /> },
            { path: 'activate', element: <ActivatePage /> },
            { path: 'signout', element: <SignOutPage /> },
          ],
        },
        {
          element: <RequireUnauthenticated />,
          children: [
            { path: 'signin', element: <SignInPage /> },
            { path: 'register', element: <RegisterPage /> },
            { path: 'signup', element: <Navigate to="/register" replace /> },
            { path: 'forgot-password', element: <ForgotPasswordPage /> },
            {
              path: 'reset-link',
              element: <Navigate to="/forgot-password" replace />,
            },
          ],
        },
        { path: '*', element: <Navigate to="/" replace /> },
      ],
    },
  ],
  { basename: import.meta.env.BASE_URL }
);

const theme = createTheme();

export default function App() {
  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <ApolloProvider>
              <AuthProvider>
                <RouterProvider router={router} />
                <LegalErrorHandler />
              </AuthProvider>
            </ApolloProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}
