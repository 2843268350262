import { Stack } from '@mui/material';

type ErrorPageActionsProps = {
  children: React.ReactNode;
};

export function ErrorPageActions({ children }: ErrorPageActionsProps) {
  return (
    <Stack spacing={1} direction="row" mt={1}>
      {children}
    </Stack>
  );
}
