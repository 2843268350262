export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.06644 18.0426L2.99989 12.9761L8.06644 7.90952"
      strokeWidth="1.5"
    />
    <path d="M15.7969 13.1333L3.52415 13.1333" strokeWidth="1.5" />
    <path
      d="M7.5 5.30559C8.16302 3.94079 9.56255 3 11.1818 3H16.0909C18.3503 3 20.1818 4.83156 20.1818 7.09091V16.9091C20.1818 19.1684 18.3503 21 16.0909 21H11.1818C10.5824 21 10.013 20.8711 9.5 20.6394"
      strokeWidth="1.5"
    />
  </svg>
);
