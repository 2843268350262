import { Box } from '@mui/material';
import { ErrorEyesIllustration } from '../../assets/illustrations';
import { Page } from '../Page';

type ErrorPageProps = {
  title: string;
  children: React.ReactNode;
};

export function ErrorPage({ children, title }: ErrorPageProps) {
  return (
    <Page
      title={title}
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        mx="auto"
        maxWidth={600}
      >
        <Box maxWidth={300}>
          <ErrorEyesIllustration aria-hidden />
        </Box>
        {children}
      </Box>
    </Page>
  );
}
