import { Banner, BannerActions, BannerContent, BannerIcon } from './Banner';
import { DateTime } from 'luxon';
import { Markdown } from '../Markdown';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

type MessageBannerProps =
  | {
      content: string;
      severity: 'success' | 'info' | 'warning' | 'error';
      showAt?: DateTime;
      hideAt?: DateTime;
      dissmissed?: boolean;
    } & (
      | {
          canBeClosed: true;
          onClose: () => void;
        }
      | {
          canBeClosed: false;
          onClose?: () => void;
        }
    );

export function MessageBanner({
  content,
  severity,
  canBeClosed,
  showAt,
  hideAt,
  dissmissed,
  onClose,
}: MessageBannerProps) {
  const show = (() => {
    const now = DateTime.now();
    if (showAt && now < showAt) {
      return false;
    }
    if (hideAt && now > hideAt) {
      return false;
    }
    if (canBeClosed && dissmissed) {
      return false;
    }
    return true;
  })();

  return (
    <Banner show={show} severity={severity}>
      <BannerIcon severity={severity} />
      <BannerContent>
        <Markdown>{content}</Markdown>
      </BannerContent>
      <BannerActions>
        {canBeClosed && (
          <IconButton onClick={onClose} size="small" color="inherit" edge="end">
            <CloseIcon />
          </IconButton>
        )}
      </BannerActions>
    </Banner>
  );
}
