import frontmatter from 'front-matter';
import { z } from 'zod';
import { getSessionDataAsync, USER_SESSION_DATA_KEY } from './auth';
import { getMediaApiUrl } from './config';
import {
  CLIENT_META_HEADER_NAME,
  getClientMetaHeaderValue,
  anonClient,
} from './client';

export type LegalAttributes = {
  version: number;
  date: string;
};

export type LegalMarkdown = {
  attributes: LegalAttributes;
  body: string;
};

export type LegalDocumentType = 'eula' | 'ola';

export async function getLegalMarkdown(
  document: LegalDocumentType,
  options?: RequestInit
): Promise<LegalMarkdown> {
  const response = await fetch(`/legal/${document}.md`, {
    ...options,
    headers: {
      ...options?.headers,
      [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue(),
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const text = await response.text();
  const { attributes, body } = frontmatter<LegalAttributes>(text);
  return { attributes, body };
}

function createRestResponseBodySchema<TData extends z.ZodTypeAny>(
  dataSchema: TData
) {
  return z.object({
    status: z.number(),
    message: z.string(),
    data: dataSchema.optional(),
  });
}

const mediaRestResponseBodySchema = createRestResponseBodySchema(
  z.object({
    id: z.string(),
    orgId: z.string(),
    originalFilename: z.string(),
    mimeType: z.string(),
    url: z.string(),
  })
);

export type MediaRestResponseBody = z.infer<typeof mediaRestResponseBodySchema>;

export const maxMediaSize = 1048576 * 10; // 10MB

export async function uploadMedia(
  file: Blob,
  orgId: string,
  options?: RequestInit
) {
  if (file.size > maxMediaSize) {
    throw new Error(`file size exceeded ${maxMediaSize / 1024 ** 2}MB`);
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('orgId', orgId);

  const session = await getSessionDataAsync(USER_SESSION_DATA_KEY, anonClient);

  if (!session) {
    throw new Error('no session found');
  }

  const response = await fetch(getMediaApiUrl(), {
    ...options,
    method: 'POST',
    body: formData,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${session.accessToken}`,
      [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue(),
    },
  });

  if (!response.ok) {
    throw new Error('failed to upload media');
  }

  const body = mediaRestResponseBodySchema.parse(await response.json());

  if (body.status !== 201 || !body.data) {
    throw new Error(body.message);
  }

  return body.data;
}
