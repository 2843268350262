export const AppleLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.308 3.83c-.785.976-2.102 1.708-3.153 1.708-.118 0-.237-.015-.31-.03a2.156 2.156 0 0 1-.045-.427c0-1.251.607-2.472 1.273-3.25C13.916.809 15.323.046 16.493 0c.029.137.044.305.044.473 0 1.236-.519 2.472-1.23 3.357ZM10.16 23.545c-.527.234-1.027.455-1.706.455-1.45 0-2.457-1.373-3.612-3.052C3.495 18.965 2.4 15.898 2.4 13c0-4.668 2.946-7.14 5.847-7.14.847 0 1.62.32 2.309.604.55.228 1.046.433 1.48.433.377 0 .847-.19 1.394-.414.764-.311 1.68-.684 2.707-.684.651 0 3.034.06 4.604 2.38l-.057.04c-.386.262-2.445 1.663-2.445 4.537 0 3.555 3.005 4.822 3.108 4.852l-.018.058c-.099.315-.57 1.817-1.58 3.345-.992 1.464-2.043 2.96-3.612 2.96-.774 0-1.266-.226-1.775-.458-.543-.248-1.104-.504-2.044-.504-.945 0-1.569.276-2.158.537Z"
      fill="#fff"
    />
  </svg>
);
