export function GooglePlayStoreBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="135"
      height="40"
      viewBox="0 0 1080 320"
      {...props}
    >
      <path d="M1040 320H40c-22 0-40-18-40-40V40C0 18 18 0 40 0h1000c22 0 40 18 40 40v240c0 22-18 40-40 40z" />
      <path
        fillRule="evenodd"
        d="M1080 40v240c0 22-18 40-40 40H40c-22 0-40-18-40-40V40C0 18 18 0 40 0h1000c22 0 40 18 40 40zM40 6.4A33.7 33.7 0 0 0 6.4 40v240A33.7 33.7 0 0 0 40 313.6h1000a33.7 33.7 0 0 0 33.6-33.6V40A33.7 33.7 0 0 0 1040 6.4z"
        fill="#a6a6a6"
      />
      <path
        fillRule="evenodd"
        d="M368.2 97V83.9h-10.8v-5.4h17.4v20.9q-3.8 2.8-8.5 4.2-4.6 1.3-9.8 1.3-11.5 0-18-6.6-6.4-6.7-6.4-18.7t6.4-18.6q6.5-6.8 18-6.8 4.8 0 9.1 1.2t7.9 3.5v7q-3.7-3.1-7.8-4.7-4.1-1.5-8.7-1.5-8.9 0-13.4 5t-4.5 14.9q0 9.9 4.5 14.9t13.4 5q3.5 0 6.3-.6 2.7-.6 4.9-1.9zm20 7V55.1h30.9v5.6h-24.3v14.5h23.3v5.5h-23.3v17.7h24.9v5.6zm36.9-43.3v-5.6h41.4v5.6h-17.4V104h-6.6V60.7zm71.7 43.3V55.1h6.6V104zm14.3-43.3v-5.6h41.4v5.6h-17.4V104h-6.6V60.7zm91.5-6.5q10.3 0 16.5 7 6.1 6.8 6.1 18.4 0 11.6-6.1 18.5-6.2 6.8-16.5 6.8t-16.5-6.8q-6.1-6.9-6.1-18.5t6.1-18.4q6.2-7 16.5-7zm0 5.4q-7.2 0-11.4 5.4-4.2 5.3-4.2 14.6 0 9.2 4.2 14.6t11.4 5.4q7.2 0 11.4-5.4t4.2-14.6q0-9.3-4.2-14.6-4.2-5.4-11.4-5.4zm34.3 44.4V55.1h8.9L667.5 96V55.1h6.4V104H665l-21.7-40.9V104z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1.6"
      />
      <path
        fillRule="evenodd"
        d="M579.2 208a33.6 33.6 0 0 1-34.1 34.1 33.7 33.7 0 0 1-34.2-34.1c0-19.7 15.4-34 34.2-34a33.5 33.5 0 0 1 34.1 34zm-14.9 0c0-12.2-8.9-20.6-19.2-20.6-10.3 0-19.2 8.4-19.2 20.6 0 12.2 8.9 20.7 19.2 20.7 10.3 0 19.2-8.5 19.2-20.7zm-59.6 0a33.6 33.6 0 0 1-34.1 34.1 33.7 33.7 0 0 1-34.2-34.1c0-19.7 15.4-34 34.2-34a33.5 33.5 0 0 1 34.1 34zm-14.9 0c0-12.2-8.9-20.6-19.2-20.6-10.3 0-19.2 8.4-19.2 20.6 0 12.2 8.9 20.7 19.2 20.7 10.3 0 19.2-8.5 19.2-20.7zm-59.3-23.5c.6 2.5.8 5.6.8 9 0 10.8-3 24.2-12.5 33.7a48.2 48.2 0 0 1-36.9 14.9 53.7 53.7 0 0 1-53.6-52.9 53.6 53.6 0 0 1 89.9-38.3L408 161.1a37.9 37.9 0 0 0-63.9 28.1 37.8 37.8 0 0 0 37.8 38.4c13.8 0 21.7-5.5 26.7-10.5a29.9 29.9 0 0 0 7.9-18.2h-34.6v-14.4zm315.5 15-45.5 18.8a17.3 17.3 0 0 0 16.5 10.4c7.6 0 12.9-3.8 16.7-9.4l11.6 7.7a33.8 33.8 0 0 1-28.3 15.1c-19.4 0-33.8-15-33.8-34.1 0-20.2 14.6-34 32.1-34 17.7 0 26.3 14.1 29.1 21.7zm-17.5-5.2c-1.7-4.2-6.7-7.2-12.6-7.2-7.6 0-18.2 6.7-17.8 19.8zM661.1 140H676v100h-14.9zm-24.5 36.1h14.2v61c0 25.2-14.9 35.5-32.4 35.5a32.3 32.3 0 0 1-30.1-20.1l13-5.4c2.3 5.5 8 12.1 17.1 12.1 11.2 0 18.2-7 18.2-20v-4.9h-.5a23.3 23.3 0 0 1-17.9 7.7 33.6 33.6 0 0 1-32.6-33.9 33.7 33.7 0 0 1 32.6-34.1c8.1 0 14.5 3.6 17.9 7.6h.5zm1 32.1c0-12-8-20.8-18.2-20.8-10.3 0-18.9 8.8-18.9 20.8 0 11.8 8.6 20.5 18.9 20.5 10.2 0 18.2-8.7 18.2-20.5zm209.7-37.1c0 19.1-16.3 31-32.8 31h-20.8V240h-15V140h35.8c16.5 0 32.8 12 32.8 31.1zm-14.9 0c0-7.9-6.4-17.2-17.5-17.2h-21.2v34.3h21.2c11.1 0 17.5-9.4 17.5-17.1zm105.1 30.4v38.4h-14.4v-7.8h-.5a21 21 0 0 1-19.1 9.8c-13.1 0-24.8-8.6-24.8-22 0-14.7 14.5-22.5 28.8-22.5 7.1 0 12.9 2.3 15.6 3.9v-1.1c-.1-8.2-8-12.8-15.7-12.8-5.5 0-10.8 1.8-13.6 7.3l-13.3-5.5c4.6-10.6 15.8-15.4 26.6-15.4 17.1 0 30.4 10 30.4 27.7zm-14.4 11.3a27 27 0 0 0-13.6-3.3c-7.4 0-15.9 2.9-15.9 10.7 0 6 6.8 8.5 11.7 8.5 8.7 0 16.6-6.6 17.8-15.9zm83.5-36.8-41.1 94.3H950l15.2-33.7-26.7-60.6h16.1l17.8 43.4h.5L990 176zm-151.1-36h15v100h-15z"
        fill="#fff"
      />
      <path
        d="m210.6 204.2-95.9 55.4-.2.1a23 23 0 0 1-33.9-13.9l85.1-90.4z"
        fill="#ea4335"
      />
      <path
        d="M263.9 160.2a23 23 0 0 1-12.1 20.3l-41.2 23.7-46.8-46.8 46.6-41.5 41.4 24 .1.1a23 23 0 0 1 12 20.2z"
        fill="#fbbc04"
      />
      <path
        d="m168.7 157.7-88.1 88.1q-.8-2.8-.8-5.9V80.1q0-3 .8-5.9z"
        fill="#4285f4"
      />
      <path
        d="M80.6 74.2a23 23 0 0 1 34.1-13.8l95.7 55.5-44 44.1z"
        fill="#34a853"
      />
    </svg>
  );
}

export default GooglePlayStoreBadge;
