import { decodeJwt } from 'jose';
import { loadScript, parseBoolean, parseURL } from './utils';

export const GOOGLE_DISCOVERY_URL = `https://accounts.google.com/.well-known/openid-configuration`;
export const GOOGLE_OAUTH_CLIENT_ID = `622230499640-ijuadflhgko6ro4mn6vbamdhqnpm82sp.apps.googleusercontent.com`;
export const MICROSOFT_DISCOVERY_URL = `https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration`;
export const MICROSOFT_OAUTH_CLIENT_ID = `7734a509-32f0-48c7-8ccc-05b909931f47`;
const APPLE_OAUTH_CLIENT_ID = `app.evoko.client`;
const APPLE_SCRIPT_URL = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`;

export const OAUTH_STATE_KEY = 'oauthState';
export const OAUTH_NONCE_KEY = 'oauthNonce';
export const OAUTH_PROFILE_DETAILS_KEY = 'oauthProfileDetails';

export type OAuthDiscoveryUrl =
  | typeof GOOGLE_DISCOVERY_URL
  | typeof MICROSOFT_DISCOVERY_URL;

export type OAuthClientId =
  | typeof GOOGLE_OAUTH_CLIENT_ID
  | typeof MICROSOFT_OAUTH_CLIENT_ID;

/** Generates a random enough string for use as OAuth state and nonce. */
export function generateRandomString() {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const bytes = new Uint8Array(45);
  window.crypto.getRandomValues(bytes);

  const v = Array.from(bytes).map((x) =>
    characters.charCodeAt(x % characters.length)
  );

  return String.fromCharCode.apply(null, v);
}

/** Gets and parses known params from state string.  */
export function getOAuthStateParams(state: string) {
  const searchParams = new URLSearchParams(state);

  const token = searchParams.get('token') ?? undefined;
  const returnTo = parseURL(searchParams.get('return_to') ?? '');
  const register = parseBoolean(searchParams.get('register') ?? '');

  return { token, returnTo, register };
}

/** Generates state and nonce and adds to local storage. */
export function generateOAuthStateAndNonce(options?: {
  returnTo?: URL;
  register?: boolean;
}) {
  const stateParams = new URLSearchParams({ token: generateRandomString() });

  if (options?.returnTo) {
    stateParams.append('return_to', options.returnTo.href);
  }
  if (typeof options?.register === 'boolean') {
    stateParams.append('register', String(options.register));
  }

  const state = stateParams.toString();
  const nonce = generateRandomString();
  localStorage.setItem(OAUTH_STATE_KEY, state);
  localStorage.setItem(OAUTH_NONCE_KEY, nonce);

  return { state, nonce };
}

/** Removes any state and nonce strings in local storage. */
export function removeOAuthStateAndNonce() {
  localStorage.removeItem(OAUTH_STATE_KEY);
  localStorage.removeItem(OAUTH_NONCE_KEY);
}

/**
 * Validates the provided state and nonce (picked from `idToken`) with the state
 * and nonce in local storage, **throws if validation fails**.
 * @param idToken the OAuth ID token returned by identity provider.
 * @param state the OAuth state returned by identity provider.
 */
export function validateOAuthStateAndNonce(idToken: string, state: string) {
  if (state !== localStorage.getItem(OAUTH_STATE_KEY)) {
    throw new Error('invalid OAuth 2.0 state');
  }

  const claims = decodeJwt(idToken);

  if (!claims?.nonce) {
    throw new Error('no OAuth 2.0 nonce found');
  }

  if (claims.nonce !== localStorage.getItem(OAUTH_NONCE_KEY)) {
    throw new Error('invalid OAuth 2.0 nonce');
  }
}

export type ProfileDetails = {
  name?: string;
  email?: string;
};

/**
 * Gets profile details from local storage if found and optionally an `idToken` if provided.
 * Details found in `idToken` always takes precedence in case details are found in both
 * and fallbacks on details in local storage.
 * @param idToken the OAuth ID token returned by identity provider.
 */
export function getOAuthProfileDetails(idToken?: string): ProfileDetails {
  const profileDetails: ProfileDetails = {};

  try {
    const localDetailsString = localStorage.getItem(OAUTH_PROFILE_DETAILS_KEY);

    // Check for profile details in local storage
    if (localDetailsString) {
      const localDetails = JSON.parse(localDetailsString);

      if (localDetails?.name && typeof localDetails.name === 'string') {
        profileDetails.name = localDetails.name;
      }

      if (localDetails?.email && typeof localDetails.email === 'string') {
        profileDetails.email = localDetails.email;
      }
    }

    // Check for profile details in ID token (takes precedence over local storage)
    if (idToken) {
      const claims = decodeJwt(idToken);

      if (claims?.name && typeof claims.name === 'string') {
        profileDetails.name = claims.name;
      }

      if (claims?.email && typeof claims.email === 'string') {
        profileDetails.email = claims.email;
      }
    }

    return profileDetails;
  } catch {
    return profileDetails;
  }
}

/** Sets profile details in local storage. */
export function setOAuthProfileDetails(details: ProfileDetails) {
  localStorage.setItem(OAUTH_PROFILE_DETAILS_KEY, JSON.stringify(details));
}

/** Removes profile details in local storage. */
export function removeOAuthProfileDetails() {
  localStorage.removeItem(OAUTH_PROFILE_DETAILS_KEY);
}

type OAuthSignInOptions = {
  /**
   * If provided, sets the OAuth redirect URI.
   * @defaults {@link getDefaultOAuthRedirectUri}
   */
  redirectUri?: string;
  /** If provided, passes a the email address along to OAuth provider as a hint. */
  loginHint?: string;
  /** If true, we should register the user upon successful sign in. */
  register?: boolean;
  /** If provided, we should redirect to the url upon successful sign in. */
  returnTo?: URL;
};

export async function signIn(
  discoveryUrl: OAuthDiscoveryUrl,
  clientId: OAuthClientId,
  options?: OAuthSignInOptions
) {
  // Call the discovery endpoint
  const discoveryResponse = await fetch(discoveryUrl);
  if (!discoveryResponse.ok) {
    throw new Error('discovery request failed');
  }

  const { authorization_endpoint } = await discoveryResponse.json();
  if (!authorization_endpoint && typeof authorization_endpoint !== 'string') {
    throw new Error('no authorization endpoint');
  }

  // Generate URL based on provided auth endpoint
  const url = new URL(authorization_endpoint);

  // Generate state and nonce
  const { state, nonce } = generateOAuthStateAndNonce({
    register: options?.register,
    returnTo: options?.returnTo,
  });

  // Add params to URL
  for (const [key, value] of Object.entries({
    client_id: clientId,
    redirect_uri: options?.redirectUri ?? getDefaultOAuthRedirectUri(),
    scope: 'openid email profile',
    state: state,
    response_type: 'code id_token',
    nonce: nonce,
    login_hint: options?.loginHint,
    prompt: 'select_account',
  })) {
    if (value) {
      url.searchParams.append(key, value);
    }
  }

  // Go to URL
  window.location.assign(url);
}

export type GoogleSignInOptions = OAuthSignInOptions;

export function googleSignIn(options?: OAuthSignInOptions) {
  return signIn(GOOGLE_DISCOVERY_URL, GOOGLE_OAUTH_CLIENT_ID, options);
}

export type MicrosoftSignInOptions = OAuthSignInOptions;

export function microsoftSignIn(options?: OAuthSignInOptions) {
  return signIn(MICROSOFT_DISCOVERY_URL, MICROSOFT_OAUTH_CLIENT_ID, options);
}

export type AppleSignInOptions = Omit<OAuthSignInOptions, 'loginHint'>;

export async function appleSignIn(options?: AppleSignInOptions) {
  await loadScript('apple-sign-in-script', APPLE_SCRIPT_URL);

  const { state, nonce } = generateOAuthStateAndNonce({
    returnTo: options?.returnTo,
    register: options?.register,
  });
  const redirectUri = options?.redirectUri ?? getDefaultOAuthRedirectUri();

  window.AppleID.auth.init({
    clientId: APPLE_OAUTH_CLIENT_ID,
    redirectURI: redirectUri,
  });

  return window.AppleID.auth.signIn({
    clientId: APPLE_OAUTH_CLIENT_ID,
    redirectURI: redirectUri,
    scope: 'openid email name',
    state,
    nonce,
    usePopup: true,
  });
}

/**
 * Returns the OAuth `redirect_uri` for the current location in the correct
 * format (e.g. `https://evoko.app/path`).  */
export function getDefaultOAuthRedirectUri() {
  return window.location.origin + window.location.pathname;
}

/**
 * Checks and returns the OAuth `id_token` and `state` if present in the URL
 * (e.g. `https://evoko.app/path#id_token=sometoken&state=somestate`).
 */
export function getOAuthRedirectUriParams() {
  const params = new URLSearchParams(window.location.hash.substring(1));
  const state = params.get('state');
  const idToken = params.get('id_token');

  if (!state || !idToken) {
    return;
  }

  return { state, idToken };
}
