import { Typography, TypographyProps } from '@mui/material';

type ErrorPageDescriptionProps = TypographyProps;

export function ErrorPageDescription({
  children,
  ...props
}: ErrorPageDescriptionProps) {
  return (
    <Typography color="text.secondary" {...props}>
      {children}
    </Typography>
  );
}
