import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import OrganizationLogo from '../OrganizationLogo';

type OrganizationCardProps = {
  logoUrl?: string;
  logoDimmed?: boolean;
} & CardProps;

export function OrganizationCard({
  logoUrl,
  logoDimmed,
  children,
  ...cardProps
}: OrganizationCardProps) {
  return (
    <Card
      sx={{
        minHeight: 120,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        py: 1,
        px: 2,
      }}
      {...cardProps}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        height="100%"
        width="100%"
      >
        <OrganizationLogo
          width={80}
          height={80}
          src={logoUrl}
          dimmed={logoDimmed}
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          flex={1}
          width="100%"
          minWidth={0}
          minHeight={80}
        >
          {children}
        </Box>
      </Box>
    </Card>
  );
}

export function OrganizationCardTitle(props: TypographyProps) {
  return <Typography variant="h2" noWrap {...props} />;
}

export function OrganizationCardDescription(props: TypographyProps) {
  return <Typography variant="body2" fontWeight="bold" {...props} />;
}

export function OrganizationCardActions(props: BoxProps) {
  return <Box display="flex" gap={1} width="100%" mt="auto" {...props} />;
}
