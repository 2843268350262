export const BuildingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="4.71423"
      y="4"
      width="9.71429"
      height="17"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <rect x="7.14282" y="6.42871" width="1.21429" height="2.42857" />
    <rect x="10.7856" y="6.42871" width="1.21429" height="2.42857" />
    <rect x="7.14282" y="11.2856" width="1.21429" height="2.42857" />
    <rect x="10.7856" y="11.2856" width="1.21429" height="2.42857" />
    <rect
      x="14.4285"
      y="13.7144"
      width="4.85714"
      height="7.28571"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
