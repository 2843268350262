import {
  useEffect,
  useContext,
  useReducer,
  createContext,
  useRef,
  useCallback,
} from 'react';
import { ApolloClient, ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import {
  googleSignIn,
  microsoftSignIn,
  appleSignIn,
  getOAuthRedirectUriParams,
  ProfileDetails,
  getOAuthProfileDetails,
  removeOAuthProfileDetails,
  setOAuthProfileDetails,
  validateOAuthStateAndNonce,
  removeOAuthStateAndNonce,
  getOAuthStateParams,
  GoogleSignInOptions,
  MicrosoftSignInOptions,
  AppleSignInOptions,
} from './oauth';
import {
  authenticateWithOIDC,
  authenticateWithEmail,
  getSessionData,
  registerWithOIDC,
  registerWithEmail,
  removeSessionData,
  setSessionData,
  USER_SESSION_DATA_KEY,
} from './auth';
import { anonClient as client } from './client';

type RegistrationDetails = {
  idToken: string;
  state: string;
  profileDetails: ProfileDetails;
};

type AuthError = {
  type: 'signin-email' | 'signin-oauth' | 'register-email' | 'register-oauth';
  content: ApolloError | Error;
};

type State =
  | {
      status: 'unauthenticated' | 'loading' | 'authenticated';
      error: undefined;
      registrationDetails: undefined;
    }
  | {
      status: 'complete_profile';
      error: undefined;
      registrationDetails: RegistrationDetails;
    }
  | {
      status: 'error';
      error: AuthError;
      registrationDetails: undefined;
    };

type Action =
  | {
      type:
        | 'SET_LOADING'
        | 'SIGN_IN'
        | 'SIGN_OUT'
        | 'CANCEL_COMPLETE_PROFILE'
        | 'CLEAR_ERROR'
        | 'ABORT_SIGN_IN';
    }
  | { type: 'SET_ERROR'; data: { error: AuthError } }
  | {
      type: 'COMPLETE_PROFILE';
      data: { registrationDetails: RegistrationDetails };
    };

const reducer = (state: State, action: Action): State => {
  switch (state.status) {
    case 'unauthenticated': {
      switch (action.type) {
        case 'SET_LOADING': {
          return { ...state, status: 'loading' };
        }
        default:
          return state;
      }
    }
    case 'loading': {
      switch (action.type) {
        case 'SIGN_IN': {
          return { ...state, status: 'authenticated' };
        }
        case 'COMPLETE_PROFILE': {
          const { registrationDetails } = action.data;
          return { ...state, status: 'complete_profile', registrationDetails };
        }
        case 'SET_ERROR': {
          const { error } = action.data;
          return { ...state, status: 'error', error };
        }
        case 'ABORT_SIGN_IN': {
          return { ...state, status: 'unauthenticated' };
        }
        default:
          return state;
      }
    }
    case 'authenticated': {
      switch (action.type) {
        case 'SIGN_OUT': {
          return { ...state, status: 'unauthenticated' };
        }
        default:
          return state;
      }
    }
    case 'complete_profile': {
      switch (action.type) {
        case 'SET_LOADING': {
          return {
            ...state,
            status: 'loading',
            registrationDetails: undefined,
          };
        }
        case 'CANCEL_COMPLETE_PROFILE': {
          return {
            ...state,
            status: 'unauthenticated',
            registrationDetails: undefined,
          };
        }
        default:
          return state;
      }
    }
    case 'error': {
      switch (action.type) {
        case 'SET_LOADING': {
          return { ...state, status: 'loading', error: undefined };
        }
        case 'CLEAR_ERROR': {
          return { ...state, status: 'unauthenticated', error: undefined };
        }
        default:
          return state;
      }
    }
  }
};

const initialState: State = (() => {
  const accessToken = getSessionData(USER_SESSION_DATA_KEY)?.accessToken;
  const status = accessToken ? 'authenticated' : 'unauthenticated';
  return { status, error: undefined, registrationDetails: undefined };
})();

function errorWithFallback(
  error: unknown,
  fallback: string
): ApolloError | Error {
  if (error instanceof ApolloError || error instanceof Error) {
    return error;
  }
  return new Error(fallback);
}

type EmailSignInOptions =
  | {
      returnTo?: URL;
      email: string;
      password: string;
      register?: false;
    }
  | {
      returnTo?: URL;
      email: string;
      password: string;
      name: string;
      register: true;
    };

type AuthContextValue = {
  authenticated: boolean;
  loading: boolean;
  error?: AuthError;
  completeProfile: boolean;
  completeProfileHints?: ProfileDetails;
  signInWithEmail: (options: EmailSignInOptions) => Promise<void>;
  signInWithGoogle: (options: GoogleSignInOptions) => Promise<void>;
  signInWithMicrosoft: (options: MicrosoftSignInOptions) => Promise<void>;
  signInWithApple: (options: AppleSignInOptions) => Promise<void>;
  signOut: (client: ApolloClient<unknown>) => void;
  clearError: () => void;
  submitCompleteProfile: (details: Required<ProfileDetails>) => Promise<void>;
  cancelCompleteProfile: (touchedDetails: ProfileDetails) => void;
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const unauthenticated = state.status === 'unauthenticated';
  const authenticated = state.status === 'authenticated';
  const loading = state.status === 'loading';
  const error = state.status === 'error';
  const completeProfile = state.status === 'complete_profile';
  const completeProfileHints = state.registrationDetails?.profileDetails;

  const handledOAuthRedirect = useRef(false);

  // Handle OAuth redirect from Microsoft and Google
  useEffect(() => {
    async function handleOAuthRedirect() {
      const params = getOAuthRedirectUriParams();
      if (!params) {
        return;
      }
      const { idToken, state } = params;
      // Get `sign_up` and `return_to` params from state string
      const { register, returnTo } = getOAuthStateParams(state);
      try {
        dispatch({ type: 'SET_LOADING' });
        // Validate state and nonce
        validateOAuthStateAndNonce(idToken, state);
        // If valid, remove from location storage
        removeOAuthStateAndNonce();
        // Always prompt the user to complete their profile when signing up with an ID token.
        // Temporarily hold on to `idToken` and `state` as `registrationDetails` so that
        // we can resume signing up the user in `submitCompleteProfile`.
        if (register) {
          dispatch({
            type: 'COMPLETE_PROFILE',
            data: {
              registrationDetails: {
                idToken,
                state,
                profileDetails: getOAuthProfileDetails(idToken),
              },
            },
          });
          return;
        }
        const data = await authenticateWithOIDC({ client, idToken });
        // Upon successful sign in, store session in local storage
        setSessionData(USER_SESSION_DATA_KEY, data);
        // If a `return_to` url was provided, redirect...
        if (returnTo) {
          window.location.replace(returnTo);
          return;
        }
        // Otherwise transition to the `authenticated` state
        dispatch({ type: 'SIGN_IN' });
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          data: {
            error: {
              type: register ? 'register-oauth' : 'signin-oauth',
              content: errorWithFallback(err, 'something went wrong'),
            },
          },
        });
      }
    }

    if (unauthenticated && !handledOAuthRedirect.current) {
      handledOAuthRedirect.current = true;
      handleOAuthRedirect();
    }
  }, [unauthenticated]);

  // Set sentry user when authenticated
  useEffect(() => {
    if (authenticated) {
      const userId = getSessionData(USER_SESSION_DATA_KEY)?.subjectId;
      Sentry.setUser({ id: userId });
      return;
    }
    Sentry.setUser(null);
  }, [authenticated]);

  // Sign in using email and password
  async function signInWithEmail(options: EmailSignInOptions) {
    if (!unauthenticated && !error) {
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING' });
      if (options.register) {
        const { email, password, name } = options;
        // NOTE: currently setting eula to `0` due to OLA reasons
        // but keeping this around since we may want to reuse it soon
        // const markdown = await getLegalMarkdown('eula');
        const eula = '0'; // markdown.attributes.version.toString();
        const data = await registerWithEmail({
          client,
          input: { email, password, name, eula },
        });
        setSessionData(USER_SESSION_DATA_KEY, data);
      } else {
        const { email, password } = options;
        const data = await authenticateWithEmail({ client, email, password });
        setSessionData(USER_SESSION_DATA_KEY, data);
      }
      // If a `return_to` url was provided, redirect...
      if (options.returnTo) {
        window.location.replace(options.returnTo);
        return;
      }
      // Otherwise transition to the `authenticated` state
      dispatch({ type: 'SIGN_IN' });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        data: {
          error: {
            type: options.register ? 'register-email' : 'signin-email',
            content: errorWithFallback(err, 'something went wrong'),
          },
        },
      });
    }
  }

  // Sign in using Google
  async function signInWithGoogle(options: GoogleSignInOptions) {
    if (!unauthenticated && !error) {
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING' });
      await googleSignIn(options);
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        data: {
          error: {
            type: options.register ? 'register-oauth' : 'signin-oauth',
            content: errorWithFallback(err, 'something went wrong'),
          },
        },
      });
    }
  }

  // Sign in using Microsoft
  async function signInWithMicrosoft(options: MicrosoftSignInOptions) {
    if (!unauthenticated && !error) {
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING' });
      await microsoftSignIn(options);
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        data: {
          error: {
            type: options.register ? 'register-oauth' : 'signin-oauth',
            content: errorWithFallback(err, 'something went wrong'),
          },
        },
      });
    }
  }

  // Sign in using Apple
  async function signInWithApple(options: AppleSignInOptions) {
    if (!unauthenticated && !error) {
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING' });
      const { authorization, user } = await appleSignIn(options);
      const { id_token: idToken, state } = authorization;
      // Validate state and nonce
      validateOAuthStateAndNonce(idToken, state);
      // If valid, remove state and nonce in local storage
      removeOAuthStateAndNonce();
      // Always prompt the user to complete their profile when signing up with ID token.
      // Temporarily hold on to `idToken` and `state` as `registrationDetails` so that
      // we can resume signing up the user in `submitCompleteProfile`.
      if (options.register) {
        const profileDetails = getOAuthProfileDetails();
        // If profile details are provided by Apple then use those
        if (user) {
          profileDetails.name = `${user.name.firstName} ${user.name.lastName}`;
          profileDetails.email = user.email;
        }
        dispatch({
          type: 'COMPLETE_PROFILE',
          data: { registrationDetails: { idToken, state, profileDetails } },
        });
        return;
      }
      const data = await authenticateWithOIDC({ client, idToken });
      // Upon successful sign in, store session in local storage
      setSessionData(USER_SESSION_DATA_KEY, data);
      // If a `return_to` url was provided, redirect...
      if (options.returnTo) {
        window.location.replace(options.returnTo);
        return;
      }
      // Otherwise transition to the `authenticated` state
      dispatch({ type: 'SIGN_IN' });
    } catch (err) {
      if (
        typeof err === 'object' &&
        err !== null &&
        'error' in err &&
        err.error === 'popup_closed_by_user'
      ) {
        dispatch({ type: 'ABORT_SIGN_IN' });
        return;
      }
      dispatch({
        type: 'SET_ERROR',
        data: {
          error: {
            type: options.register ? 'register-oauth' : 'signin-oauth',
            content: errorWithFallback(err, 'something went wrong'),
          },
        },
      });
    }
  }

  /** Submits the OAuth profile verification and resumes sign up. */
  async function submitCompleteProfile({
    name,
    email,
  }: Required<ProfileDetails>) {
    if (!completeProfile) {
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING' });
      // NOTE: currently setting eula to `0` due to OLA reasons
      // but keeping this around since we may want to reuse it soon
      // const markdown = await getLegalMarkdown('eula');
      const eula = '0'; // markdown.attributes.version.toString();
      const { registrationDetails } = state;

      const data = await registerWithOIDC({
        client,
        input: { idToken: registrationDetails.idToken, email, name, eula },
      });
      // Upon successful sign up, store session in local storage
      setSessionData(USER_SESSION_DATA_KEY, data);
      // Remove any profile details in local storage
      removeOAuthProfileDetails();
      // If a `return_to` url was provided, redirect...
      const { returnTo } = getOAuthStateParams(registrationDetails.state);
      if (returnTo) {
        window.location.replace(returnTo);
        return;
      }
      // Otherwise transition to the `authenticated` state
      dispatch({ type: 'SIGN_IN' });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        data: {
          error: {
            type: 'register-oauth',
            content: errorWithFallback(err, 'something went wrong'),
          },
        },
      });
    }
  }

  /** Cancels the OAuth profile verification / sign up. */
  function cancelCompleteProfile(touchedDetails: ProfileDetails) {
    if (!completeProfile) {
      return;
    }
    // Upon canceling sign up, hold on to any profile details touched/entered by the
    // user in local storage so that it can be prefilled on the next sign up attempt
    setOAuthProfileDetails(touchedDetails);
    dispatch({ type: 'CANCEL_COMPLETE_PROFILE' });
  }

  /** Sign out and clear the store in the given client. */
  function signOut(client: ApolloClient<unknown>) {
    if (!authenticated) {
      return;
    }
    removeSessionData(USER_SESSION_DATA_KEY);
    client.clearStore();
    dispatch({ type: 'SIGN_OUT' });
  }

  const clearError = useCallback(() => {
    dispatch({ type: 'CLEAR_ERROR' });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        loading,
        completeProfile,
        completeProfileHints,
        error: state.error,
        signInWithEmail,
        signInWithGoogle,
        signInWithMicrosoft,
        signInWithApple,
        signOut,
        clearError,
        submitCompleteProfile,
        cancelCompleteProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`${useAuth.name} must be used within ${AuthProvider.name}`);
  }
  return context;
}
