import { z } from 'zod';
import useSWR from 'swr';
import { fromOptDateTimeScalar, getStorageUrl } from '@evoko/api';
import { useDismissedNotifications } from '../../hooks';
import { MessageBanner } from './MessageBanner';

class FetchError extends Error {
  public status: number;
  constructor(message: string, status: number) {
    super(message);
    this.name = 'FetchError';
    this.status = status;
  }
}

// Should align with `/schema/system-messages.schema.json`
const systemMessagesSchema = z.object({
  messages: z
    .object({
      id: z.string(),
      content: z.string(),
      type: z.enum(['SUCCESS', 'INFO', 'WARNING', 'ERROR']),
      canBeClosed: z.boolean(),
      showAt: z.string().datetime({ offset: true }).optional().nullable(),
      hideAt: z.string().datetime({ offset: true }).optional().nullable(),
    })
    .array(),
});

async function getSystemMessages(url: string) {
  const response = await fetch(url);

  if (!response.ok) {
    throw new FetchError(response.statusText, response.status);
  }

  const data = await response.json();
  return systemMessagesSchema.parse(data);
}

export function SystemMessageBanners() {
  const { data } = useSWR(
    getStorageUrl('messages/system.json').href,
    getSystemMessages,
    {
      shouldRetryOnError: (error) => {
        if (error instanceof FetchError) {
          return error.status !== 404;
        }
        return true;
      },
    }
  );
  const [dismissedIds, setDismissedIds] = useDismissedNotifications();

  if (!data) {
    return null;
  }

  return (
    <>
      {data.messages.map(
        ({ id, content, type, canBeClosed, showAt, hideAt }) => (
          <MessageBanner
            key={id}
            content={content}
            severity={type.toLocaleLowerCase() as Lowercase<typeof type>}
            canBeClosed={canBeClosed}
            showAt={fromOptDateTimeScalar(showAt) ?? undefined}
            hideAt={fromOptDateTimeScalar(hideAt) ?? undefined}
            dissmissed={dismissedIds?.includes(id)}
            onClose={() => setDismissedIds([...(dismissedIds ?? []), id])}
          />
        )
      )}
    </>
  );
}
