import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Typography, Button, Collapse, Alert } from '@mui/material';
import {
  passwordRules,
  PasswordTextField,
  Page,
  Form,
} from '@evoko/components';
import type { SignInLocationState } from '../SignInPage';
import locale from '../../locale';
import { gql, useMutation } from '@apollo/client';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../generated/graphql';
import { PageLogoHeader } from '../../components/PageLogoHeader';
import PageContent from '../../components/PageContent';

const M_RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      email
    }
  }
`;

type ResetPasswordFormData = {
  password: string;
  repeatedPassword: string;
};

const LL = locale.pages.resetPassword;

export default function ResetPasswordPage() {
  const token = useSearchParams()[0].get('token');
  const navigate = useNavigate();

  const { control, handleSubmit, formState, watch } =
    useForm<ResetPasswordFormData>({
      mode: 'onChange',
      defaultValues: {
        password: '',
        repeatedPassword: '',
      },
    });

  const [resetPassword, { loading, error }] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(M_RESET_PASSWORD, {
    onCompleted: ({ resetPassword: { email } }) => {
      const state: SignInLocationState = { email, showForm: true };
      navigate('/signin', { state });
    },
  });

  if (!token) {
    return <Navigate to="/forgot-password" />;
  }

  const { isValid, isDirty, touchedFields } = formState;
  const password = watch('password');
  const repeatedPassword = watch('repeatedPassword');
  const canSubmit = isValid && isDirty && password === repeatedPassword;

  const onSubmit = async ({ password }: ResetPasswordFormData) => {
    await resetPassword({ variables: { token, password } }).catch();
  };

  return (
    <Page title={LL.header} loading={loading} minHeight="100%">
      <PageLogoHeader />
      <PageContent component="main" maxWidth={410}>
        <Typography variant="h1" align="center">
          {LL.header}
        </Typography>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="password"
            control={control}
            rules={{ ...passwordRules, required: LL.password.required }}
            render={({
              field,
              fieldState: { isTouched, error },
              formState: { isSubmitting },
            }) => (
              <PasswordTextField
                {...field}
                sx={{ mb: 1 }}
                label={LL.password.label}
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={!!error && isTouched}
                helperText={(isTouched && error?.message) || ' '}
              />
            )}
          />
          <Controller
            name="repeatedPassword"
            control={control}
            rules={{ ...passwordRules, required: LL.repeatedPassword.required }}
            render={({
              field,
              fieldState: { isTouched, error },
              formState: { isSubmitting },
            }) => (
              <PasswordTextField
                {...field}
                sx={{ mb: 1 }}
                label={LL.repeatedPassword.label}
                variant="outlined"
                fullWidth
                disabled={isSubmitting}
                error={!!error && isTouched}
                helperText={(isTouched && error?.message) || ' '}
              />
            )}
          />
          <Collapse
            in={
              isValid &&
              touchedFields.password &&
              touchedFields.repeatedPassword &&
              password !== repeatedPassword
            }
            unmountOnExit
          >
            <Alert severity="error" sx={{ mb: 1 }}>
              {LL.alerts.passwordsDoesNotMatch}
            </Alert>
          </Collapse>
          {/* FIXME: Improve error messages (e.g. expired token, token not found). Generic error for the moment. */}
          <Collapse in={!!error} unmountOnExit>
            <Alert severity="error" sx={{ mb: 1 }}>
              {LL.alerts.error}
            </Alert>
          </Collapse>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={!canSubmit || loading}
            sx={{ mt: 1 }}
          >
            {LL.buttons.setPasswordButton}
          </Button>
        </Form>
      </PageContent>
    </Page>
  );
}
