import Box, { BoxProps } from '@mui/material/Box';

type Props = BoxProps & {
  children: React.ReactNode;
  disableGutters?: boolean;
};

export default function PageContent({
  children,
  disableGutters = false,
  sx,
  ...props
}: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        px: disableGutters ? undefined : { xs: 2, sm: 3 },
        pb: 6,
        mx: 'auto',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
