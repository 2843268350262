/**
 * Helper function for improving type safety when referring to a key in an object.
 * @param key one of the keys in the provided object `T`.
 * @returns the provided `key`.
 */
export const keyOf = <T>(key: keyof T): keyof T => key;

type Token = string | number | symbol;
type Primitive = string | number | bigint | boolean | symbol | null | undefined;

/** Type guard for checking if an `object` has the provided `key`. */
export function isKeyOfObject<Obj extends Record<Token, unknown>>(
  key: Token,
  object: Obj
): key is keyof Obj {
  return key in object;
}

/** Type guard for checking if an `object` has the provided primitive `value`. */
export function isValueOfObject<Obj extends Record<Token, Primitive>>(
  value: Primitive,
  object: Obj
): value is Obj[keyof Obj] {
  return Object.values(object).includes(value);
}

export function isObject(object: unknown): object is object {
  return object !== null && typeof object === 'object';
}

export function isPrimitive(value: unknown): value is Primitive {
  switch (typeof value) {
    case 'bigint':
      return true;
    case 'boolean':
      return true;
    case 'number':
      return true;
    case 'object':
      return value === null;
    case 'string':
      return true;
    case 'symbol':
      return true;
    case 'undefined':
      return true;
    default:
      return false;
  }
}

/** Creates a readonly two-way map. Useful for accessing key by value or vice versa. */
export class ReadonlyBidirectionalMap<K extends Token, V extends Token> {
  /** The provided map */
  public forward: ReadonlyMap<K, V>;
  /** The reversed map of `forward` */
  public reverse: ReadonlyMap<V, K>;

  /** @param iterable the key/value pairs which the map should hold */
  public constructor(iterable: [K, V][]) {
    this.forward = new Map<K, V>(iterable);
    this.reverse = new Map<V, K>(iterable.map(([k, v]) => [v, k]));
  }
}

/** Modified from https://github.com/react-hook-form/react-hook-form/issues/1005#issuecomment-988380981 */
export function stopPropagate(callback: () => void) {
  return (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    return callback();
  };
}

/**
 * This function abbreviates a number to a string with a maximum decimal place.
 * @param stat number to abbreviate
 * @param navigatorLanguage defaults to 'en-US'
 * @param maximumFractionDigits defaults to 1
 * @param defaultValue defaults to '0'
 * @returns string | undefined
 */
export function abbreviateNumber(
  stat: number | undefined,
  navigatorLanguage = 'en-US',
  defaultValue = '0',
  maximumFractionDigits = 1
): string {
  if (!stat) {
    return defaultValue;
  }
  return Intl.NumberFormat(navigatorLanguage, {
    notation: 'compact',
    maximumFractionDigits,
  }).format(stat);
}
