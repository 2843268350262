import { init, BrowserOptions } from '@sentry/react';
import { getBackendUrl } from './config';

const SENTRY_DSN =
  'https://f919380b42aa48f39f3c9857cae9b14d@o1146552.ingest.sentry.io/6396579';

const SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE;

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export function initializeSentry(options?: BrowserOptions) {
  if (import.meta.env.PROD) {
    init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release: SENTRY_RELEASE,
      tunnel: getBackendUrl('sentry').href,
      ...options,
    });
  }
}
