import { Helmet } from 'react-helmet-async';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import useLoadingDelay from '../hooks/useLoadingDelay';

type PageProps = BoxProps & {
  title: string;
  loading?: boolean;
  children: React.ReactNode;
};

export function Page({
  title,
  loading = false,
  children,
  ...props
}: PageProps) {
  const showLoading = useLoadingDelay(loading);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box position="relative" width="100%" {...props}>
        {showLoading && (
          <Box
            position="absolute"
            top={0}
            width="100%"
            sx={({ zIndex }) => ({ zIndex: zIndex.appBar + 1 })}
          >
            <LinearProgress />
          </Box>
        )}
        {children}
      </Box>
    </>
  );
}

export default Page;
