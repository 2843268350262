import { Typography, TypographyProps } from '@mui/material';

type ErrorPageTitleProps = TypographyProps<'h1'>;

export function ErrorPageTitle({ children, ...props }: ErrorPageTitleProps) {
  return (
    <Typography component="h1" {...props} variant="h1">
      {children}
    </Typography>
  );
}
