import { useState } from 'react';
import { Box, Typography, Button, Collapse, Alert } from '@mui/material';
import locale from '../../../locale';
import { PaperPlaneLaptopIllustration } from '@evoko/components';
import { gql, useMutation } from '@apollo/client';
import type {
  RequestResetMutation,
  RequestResetMutationVariables,
} from '../../../generated/graphql';

const M_REQUEST_RESET = gql`
  mutation RequestReset($email: String!) {
    requestReset(email: $email)
  }
`;

type Props = {
  email: string;
};

const LL = locale.pages.forgotPassword.confirmResetSent;

export default function ConfirmResetSent({ email }: Props) {
  const [resent, setResent] = useState(false);

  const [requestReset, { error }] = useMutation<
    RequestResetMutation,
    RequestResetMutationVariables
  >(M_REQUEST_RESET, {
    onCompleted: () => {
      setResent(true);
    },
  });

  const onSubmit = async () => {
    await requestReset({
      variables: {
        email,
      },
    }).catch();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mb={4} width="100%">
        <PaperPlaneLaptopIllustration />
      </Box>
      <Typography variant="h1" pb={4} align="center">
        {LL.header}
      </Typography>
      <Typography color="text.secondary" pb={2} align="center">
        {LL.description}
      </Typography>
      <Box display="flex" flexDirection="column" width="100%" pb={2}>
        <Collapse in={resent} unmountOnExit>
          <Alert severity="success">{LL.alerts.resentEmailSuccess}</Alert>
        </Collapse>
        <Collapse in={!!error} unmountOnExit>
          <Alert severity="error">{LL.alerts.error}</Alert>
        </Collapse>
      </Box>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        onClick={onSubmit}
        disabled={resent}
        fullWidth
      >
        {!resent
          ? LL.buttons.resendButton.default
          : LL.buttons.resendButton.sent}
      </Button>
    </Box>
  );
}
