import Box, { BoxProps } from '@mui/material/Box';
import { BuildingIcon } from '../assets/icons/BuildingIcon';

type Props = BoxProps<'img'> & {
  dimmed?: boolean;
};

export function OrganizationLogo({
  src,
  width,
  height,
  dimmed = false,
  sx,
  ...props
}: Props) {
  if (!src) {
    return (
      <Box
        width={width}
        height={height}
        borderRadius={1}
        bgcolor="common.white"
        color="text.secondary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={2}
        sx={{ opacity: dimmed ? 0.4 : undefined, ...sx }}
        {...props}
      >
        <BuildingIcon width="100%" height="100%" />
      </Box>
    );
  }

  return (
    <Box
      component="img"
      src={src}
      alt="Organization logo"
      width={width}
      height={height}
      borderRadius={1}
      bgcolor="common.white"
      sx={{ opacity: dimmed ? 0.4 : undefined, ...sx }}
      {...props}
    />
  );
}

export default OrganizationLogo;
