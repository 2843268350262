import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { getSuperAdminUrl, useAuth } from '@evoko/api';
import { useApolloClient } from '@apollo/client';
import locale from '../../locale';
import illustration from './assets/figure-by-computer.png';
import {
  LogoutIcon,
  SuperAdminIcon,
  AppleAppStoreBadge,
  GooglePlayStoreBadge,
  Page,
} from '@evoko/components';
import { useProfileQuery } from '../../hooks';
import { OrganizationList } from './components/OrganizationList';
import { CreateOrganization } from './components/CreateOrganization';
import { JoinOrganization } from './components/JoinOrganization';

export const leftColumnBottomHeight = 2.4;

const LL = locale.pages.profile;

function ProfileLayout() {
  const { signOut } = useAuth();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { profile } = useProfileQuery();

  return (
    <Page
      title={LL.title}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="center"
      height="100%"
      maxWidth="930px"
      margin="auto"
      px={2}
    >
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        minHeight="100%"
        maxWidth={{ xs: 550, md: 372 }}
        width="100%"
        py={4}
        mx={{ md: 'auto' }}
        height="inherit"
      >
        <Outlet />

        <Stack
          justifyContent="space-between"
          alignItems="center"
          mt="auto"
          direction="row"
          spacing={2}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ background: 'white' }}
            />
          }
          height={({ spacing }) => spacing(leftColumnBottomHeight)}
        >
          <Button
            color="primary"
            startIcon={<LogoutIcon aria-hidden={true} />}
            onClick={() => {
              signOut(client);
              navigate('/signin');
            }}
          >
            <Typography component="span" textTransform="none">
              {LL.signOut}
            </Typography>
          </Button>
          {profile?.superAdmin && (
            <Button
              color="primary"
              startIcon={<SuperAdminIcon aria-hidden={true} />}
              href={getSuperAdminUrl().href}
            >
              <Typography component="span" textTransform="none">
                {LL.superAdmin}
              </Typography>
            </Button>
          )}
        </Stack>
      </Box>

      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        minHeight="100%"
        ml={{ xs: '0', md: 6 }}
        py={4}
      >
        <Box maxWidth={420} margin="auto 0 auto auto">
          <Box
            component="img"
            src={illustration}
            alt="figure by computer"
            width="100%"
          />
        </Box>
        <Box
          maxHeight={40}
          display="flex"
          justifyContent={{ xs: 'center', md: 'end' }}
          gap={2}
          my={{ xs: 8, md: 0 }}
        >
          <a href="https://apps.apple.com/us/app/evoko-workplace/id1615758561">
            <AppleAppStoreBadge aria-hidden />
            <span style={visuallyHidden}>Apple app store</span>
          </a>
          <a href="https://play.google.com/store/apps/details?id=se.evoko.workplace">
            <GooglePlayStoreBadge aria-hidden />
            <span style={visuallyHidden}>Google play store</span>
          </a>
        </Box>
      </Box>
    </Page>
  );
}

export default function ProfilePage() {
  return (
    <Routes>
      <Route element={<ProfileLayout />}>
        <Route index element={<OrganizationList />} />
        <Route path="create-organization" element={<CreateOrganization />} />
        <Route path="join-organization" element={<JoinOrganization />} />
      </Route>
    </Routes>
  );
}
