export async function waitTimeout(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Appends a `<script>` to the document `<body>` if not already present and
 *  then awaits the `onload` event. Rejects if the `onerror` event is fired.
 * @param id sets the id attribute for the element. Should be unique.
 * @param src the URL for the script.
 */
export function loadScript(id: string, src: string): Promise<void | Event> {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);

    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.body.appendChild(script);
    script.onload = (e) => resolve(e);
    script.onerror = (e) => reject(e);
  });
}

/**
 * Parses string boolean (`'true'` or `'false'`) to a boolean.
 * Returns `undefined` if the string has any other value.
 */
export function parseBoolean(string: string) {
  switch (string.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return;
  }
}

/** Parses string URL (e.g. `https://evoko.app`). Returns `undefined` if invalid. */
export function parseURL(string: string) {
  try {
    return new URL(string);
  } catch {
    return;
  }
}
