import { useMemo } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { LegalDialog } from './LegalDialog';
import { useApolloLinkErrors, useAuth, LegalAttributes } from '@evoko/api';
import type {
  UpdateProfileEulaMutation,
  UpdateProfileEulaMutationVariables,
} from '../generated/graphql';

const M_UPDATE_PROFILE_EULA = gql`
  mutation UpdateProfileEula($version: String!) {
    updateProfile(input: { eula: $version }) {
      id
    }
  }
`;

/**
 * Component for listening for the `eulaNotAccepted` GraphQL error and promting
 * a `LegalDialog` if the error is present with options to accept the latest EULA
 * or cancel (sign out).
 */
export function LegalErrorHandler() {
  const client = useApolloClient();
  const { signOut } = useAuth();
  const linkErrors = useApolloLinkErrors();

  const legalError = useMemo(
    () =>
      linkErrors.find(
        ({ error }) => error.extensions?.code === 'eulaNotAccepted'
      ),
    [linkErrors]
  );

  const [updateProfileEula, { loading, error }] = useMutation<
    UpdateProfileEulaMutation,
    UpdateProfileEulaMutationVariables
  >(M_UPDATE_PROFILE_EULA);

  async function onAccept({ version }: LegalAttributes) {
    try {
      await updateProfileEula({ variables: { version: version.toString() } });
      legalError?.resolve?.();
    } catch {
      // Catch error to prevent it being thrown unhandled but do nothing with
      // it since it's handled via `acceptError` instead.
    }
  }

  function onCancel() {
    signOut(client);
    legalError?.reject?.();
  }

  const acceptError = error
    ? error.graphQLErrors?.[0].message ?? 'something went wrong'
    : undefined;

  return (
    <LegalDialog
      variant="accept"
      document="eula"
      open={!!legalError}
      acceptLoading={loading}
      acceptError={acceptError}
      onAccept={onAccept}
      onCancel={onCancel}
    />
  );
}
