import { useAuth } from '@evoko/api';
import { BiampLogo, Page } from '@evoko/components';
import { Box, Button, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import background from './assets/landing-page-background.jpg';
import locale from '../../locale';

const LL = locale.pages.landing;

export default function LandingPage() {
  const { authenticated } = useAuth();
  const now = new Date();
  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      p={{ xs: 0, sm: 1 }}
      sx={{
        backgroundImage: { xs: 'none', sm: `url("${background}")` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={2}
        p={{ xs: 2, sm: 4 }}
        borderRadius={{ xs: 0, sm: 1 }}
        maxWidth={534}
        bgcolor="background.default"
      >
        <BiampLogo width={100} />
        <Box>
          <Typography
            variant="h0"
            component="h1"
            sx={{ wordSpacing: 99999 }}
            fontWeight={400}
          >
            {LL.title}
          </Typography>
          <Typography my={2}>{LL.subtitle}</Typography>
          {authenticated ? (
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to="/profile"
              size="large"
              sx={{ minWidth: '50%' }}
            >
              {LL.profile}
            </Button>
          ) : (
            <Box display="flex" gap={1}>
              <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to="/signin"
                size="large"
                fullWidth
              >
                {LL.signIn}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                component={RouterLink}
                to="/register"
                size="large"
                fullWidth
              >
                {LL.register}
              </Button>
            </Box>
          )}
        </Box>
        <Box
          component="ul"
          display="flex"
          alignItems="baseline"
          gap={1}
          color="text.secondary"
          p={0}
          m={0}
          sx={{ listStyle: 'none' }}
        >
          <Typography component="li">
            <Link color="inherit" underline="hover" href="https://biamp.com">
              {now.getFullYear()} Biamp®
            </Link>
          </Typography>
          <Typography component="li">
            <Link
              color="inherit"
              underline="hover"
              href="https://meetevoko.com/privacy-policy/"
            >
              Privacy
            </Link>
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}
