import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Collapse,
  Link as MuiLink,
} from '@mui/material';
import locale from '../../locale';
import { emailFormPattern } from '../../utils';
import {
  RequestResetMutation,
  RequestResetMutationVariables,
} from '../../generated/graphql';
import ConfirmResetSent from './components/ConfirmResetSent';
import { useReturnTo } from '@evoko/api';
import { Page } from '@evoko/components';
import { PageLogoHeader } from '../../components/PageLogoHeader';
import PageContent from '../../components/PageContent';

const M_REQUEST_RESET = gql`
  mutation RequestReset($email: String!) {
    requestReset(email: $email)
  }
`;

type RequestResetFormData = {
  email: string;
};

const LL = locale.pages.forgotPassword;

export default function ForgotPasswordPage() {
  const [sent, setSent] = useState(false);
  const { returnToSearch } = useReturnTo();

  const { control, handleSubmit, formState, watch } =
    useForm<RequestResetFormData>({
      mode: 'onChange',
      defaultValues: {
        email: '',
      },
    });
  const { isValid, isDirty } = formState;

  const [requestReset, { loading, error }] = useMutation<
    RequestResetMutation,
    RequestResetMutationVariables
  >(M_REQUEST_RESET, {
    onCompleted: () => {
      setSent(true);
    },
  });

  const onSubmit = async (data: RequestResetFormData) => {
    await requestReset({
      variables: {
        email: data.email,
      },
    }).catch();
  };

  return (
    <Page title={LL.header} loading={loading} minHeight="100%">
      <PageLogoHeader />
      <PageContent component="main" maxWidth={410}>
        {!sent ? (
          <>
            <Box>
              <Typography variant="h1" align="center">
                {LL.header}
              </Typography>
              <Typography align="center" mt={1} color="text.secondary">
                {LL.description}
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                rules={emailFormPattern}
                render={({ field, fieldState: { isTouched, error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    label={LL.form.email.label}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={loading}
                    error={!!error && isTouched}
                    helperText={(isTouched && error?.message) || ' '}
                  />
                )}
              />
              <Collapse in={!!error} unmountOnExit>
                {/* FIXME: improve error handling (e.g. email not found, failed to send email etc) */}
                <Alert severity="error">{LL.alerts.error}</Alert>
              </Collapse>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={!isValid || !isDirty || loading}
                fullWidth
                sx={{ mt: 2 }}
              >
                {LL.form.submitButton}
              </Button>
            </Box>
          </>
        ) : (
          <ConfirmResetSent email={watch('email')} />
        )}
        <Typography variant="body2" align="center">
          {LL.backToSignIn.text}{' '}
          <MuiLink
            color="inherit"
            component={Link}
            to={{ pathname: '/signin', search: returnToSearch?.toString() }}
          >
            {LL.backToSignIn.link}
          </MuiLink>
        </Typography>
      </PageContent>
    </Page>
  );
}
