import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useProfileQuery } from '../../../../hooks';
import locale from '../../../../locale';
import { leftColumnBottomHeight } from '../..';
import { AddOrganizationButton } from './components/AddOrganizationButton';
import { InvitationOrganizationCard } from './components/InvitationOrganizationCard';
import { MembershipOrganizationCard } from './components/MembershipOrganizationCard';

const LL = locale.pages.profile.organizationList;

export function OrganizationList() {
  const { profile, loading, error } = useProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const organizations = useMemo(
    () =>
      profile
        ? [...profile.memberships, ...profile.invitations].sort((a, b) =>
            a.orgName.localeCompare(b.orgName)
          )
        : [],
    [profile]
  );

  if (loading && !profile) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (error || !profile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Typography variant="h1">{LL.errorTitle}</Typography>
        <Typography variant="body2" color="textSecondary" mt={2}>
          {LL.errorText}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      maxHeight={({ spacing }) =>
        `calc(100% - ${spacing(leftColumnBottomHeight + 6)})`
      }
      mt={{ xs: 0, sm: 'auto' }}
      mb={6}
    >
      <Typography variant="h1">
        {profile?.name ? LL.hiWithName(profile.name) : LL.hi}
        <br />
        {LL.readyToGetToWork}
      </Typography>
      {!profile.verified && (
        <Typography variant="body2" color="text.secondary">
          {LL.verifyEmail.text}{' '}
          <MuiLink
            color="inherit"
            component={Link}
            to={{
              pathname: '/verify-email',
              search: new URLSearchParams({ email: profile.email }).toString(),
            }}
          >
            {LL.verifyEmail.link}
          </MuiLink>
        </Typography>
      )}
      {!organizations.length ? (
        <Typography variant="body2" color="text.secondary">
          {LL.description}
        </Typography>
      ) : (
        <Stack spacing={2} sx={{ overflowY: 'auto' }}>
          {organizations.map((data) => {
            switch (data.__typename) {
              case 'Membership': {
                return (
                  <MembershipOrganizationCard
                    key={data.orgId}
                    membership={data}
                  />
                );
              }
              case 'Invitation': {
                return (
                  <InvitationOrganizationCard
                    key={data.orgId}
                    invitation={data}
                    verified={profile.verified}
                  />
                );
              }
            }
          })}
        </Stack>
      )}
      <Box>
        <AddOrganizationButton />
      </Box>
    </Box>
  );
}
