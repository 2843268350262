import { emailRegex } from '@evoko/utils';
import { default as LL } from '../locale';

export const emailFormPattern = {
  required: {
    value: true,
    message: LL.forms.email.errors.required,
  },
  pattern: {
    value: emailRegex,
    message: LL.forms.email.errors.invalid,
  },
};
