import type { ProfileDetails } from '@evoko/api';
import { Form } from '@evoko/components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import locale from '../../../locale';
import { emailFormPattern } from '../../../utils';

export type ProfileDetailsFormData = {
  name: string;
  email: string;
};

type Props = {
  open: boolean;
  onCancel: (touchedDetails: ProfileDetails) => void;
  onSubmit: (details: Required<ProfileDetails>) => void;
  profileDetails?: Partial<ProfileDetailsFormData>;
};

const LL = locale.pages.register.profileDetailsDialog;
const formId = 'complete-profile-form';

export default function ProfileDetailsDialog({
  open,
  onCancel,
  onSubmit,
  profileDetails,
}: Props) {
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, touchedFields, errors },
  } = useForm<ProfileDetailsFormData>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      name: '',
    },
  });

  useEffect(() => {
    if (profileDetails) {
      reset(profileDetails);
    }
  }, [profileDetails, reset]);

  const onClose = () => {
    const touchedDetails: Partial<ProfileDetails> = {};

    const { name, email } = getValues();

    if (name && touchedFields.name && !errors.name) {
      touchedDetails.name = name;
    }

    if (email && touchedFields.email && !errors.email) {
      touchedDetails.email = email;
    }

    onCancel(touchedDetails);
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>{LL.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{LL.description}</DialogContentText>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: LL.form.name.required }}
            render={({
              field,
              fieldState: { isTouched, error },
              formState: { isSubmitting },
            }) => (
              <TextField
                {...field}
                label={LL.form.name.label}
                variant="outlined"
                margin="dense"
                fullWidth
                disabled={isSubmitting}
                error={!!error && isTouched}
                helperText={(isTouched && error?.message) || ' '}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={emailFormPattern}
            render={({
              field,
              fieldState: { isTouched, error },
              formState: { isSubmitting },
            }) => (
              <TextField
                {...field}
                type="email"
                label={LL.form.email.label}
                variant="outlined"
                margin="dense"
                fullWidth
                disabled={isSubmitting}
                error={!!error && isTouched}
                helperText={(isTouched && error?.message) || ' '}
              />
            )}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {LL.cancel}
        </Button>
        <Button
          type="submit"
          form={formId}
          color="success"
          variant="contained"
          disabled={!isValid}
        >
          {LL.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
