import { useMemo, useReducer, createContext, useContext } from 'react';
import { GraphQLError } from 'graphql';
import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import { createUserClient } from './client';

type ApolloLinkError = {
  error: GraphQLError;
  resolve?: () => void;
  reject?: () => void;
};

export type ApolloLinkErrorAction =
  | { type: 'ADD'; data: ApolloLinkError }
  | { type: 'CLEAR'; data: { code: string } };

function apolloLinkErrorReducer(
  state: ApolloLinkError[],
  action: ApolloLinkErrorAction
): ApolloLinkError[] {
  switch (action.type) {
    case 'ADD': {
      const linkError = action.data;
      return [...state, linkError];
    }
    case 'CLEAR': {
      const { code } = action.data;
      return state.filter((e) => e.error.extensions?.code !== code);
    }
  }
}

const ApolloLinkErrorContext = createContext<ApolloLinkError[]>([]);

export function ApolloProvider({ children }: { children: React.ReactNode }) {
  const [linkErrors, linkErrorDispatch] = useReducer(apolloLinkErrorReducer, []); // prettier-ignore

  // NOTE: we should only run `createUserClient` once hence `useMemo`.
  // By passing `linkErrorDispatch` we have a way to bridge errors happening
  // in our Apollo links with react state, enabling us to check for selected
  // graphQL errors and handle them globally in a more declarative way.
  const client = useMemo(() => createUserClient({ linkErrorDispatch }), []);

  return (
    <ApolloClientProvider client={client}>
      <ApolloLinkErrorContext.Provider value={linkErrors}>
        {children}
      </ApolloLinkErrorContext.Provider>
    </ApolloClientProvider>
  );
}

export function useApolloLinkErrors() {
  return useContext(ApolloLinkErrorContext);
}
