import {
  getLegalMarkdown,
  LegalAttributes,
  LegalDocumentType,
  LegalMarkdown,
} from '@evoko/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useReducer, useRef } from 'react';
import { BiampLogo } from '../assets/logos/BiampLogo';
import locale from '../locale';
import { Markdown } from './Markdown';

type State =
  | {
      status: 'idle' | 'loading';
      markdown: undefined;
      read: false;
      error: undefined;
    }
  | {
      status: 'success' | 'refreshing';
      markdown: LegalMarkdown;
      read: boolean;
      error: undefined;
    }
  | {
      status: 'error';
      markdown: undefined;
      read: false;
      error: string;
    };

type Action =
  | { type: 'ABORT' | 'LOAD' | 'READ' }
  | { type: 'SUCCESS'; data: { markdown: LegalMarkdown } }
  | { type: 'FAILURE'; data: { error: string } };

const reducer = (state: State, action: Action): State => {
  switch (state.status) {
    case 'idle': {
      switch (action.type) {
        case 'LOAD': {
          return { ...state, status: 'loading' };
        }
        default:
          return state;
      }
    }
    case 'loading': {
      switch (action.type) {
        case 'SUCCESS': {
          const { markdown } = action.data;
          return { ...state, status: 'success', markdown, error: undefined };
        }
        case 'FAILURE': {
          const { error } = action.data;
          return { ...state, status: 'error', error, markdown: undefined };
        }
        case 'ABORT': {
          return { ...state, status: 'idle' };
        }
        default:
          return state;
      }
    }
    case 'success': {
      switch (action.type) {
        case 'LOAD': {
          return { ...state, status: 'refreshing' };
        }
        case 'READ': {
          return { ...state, read: true };
        }
        default:
          return state;
      }
    }
    case 'refreshing': {
      switch (action.type) {
        case 'SUCCESS': {
          const { markdown } = action.data;
          if (
            markdown.attributes.version !== state.markdown.attributes.version
          ) {
            return {
              ...state,
              status: 'success',
              markdown,
              read: false,
            };
          }
          return { ...state, status: 'success', markdown };
        }
        case 'FAILURE': {
          const { error } = action.data;
          return {
            ...state,
            status: 'error',
            error,
            markdown: undefined,
            read: false,
          };
        }
        default:
          return state;
      }
    }
    case 'error': {
      switch (action.type) {
        case 'LOAD': {
          return { ...state, status: 'loading', error: undefined };
        }
        default:
          return state;
      }
    }
  }
};

const initialState: State = {
  status: 'idle',
  markdown: undefined,
  read: false,
  error: undefined,
};

const LL = locale.legalDialog;

type Props =
  | {
      variant: 'readonly';
      document: LegalDocumentType;
      open: boolean;
      onClose: () => void;
    }
  | {
      variant: 'accept';
      document: LegalDocumentType;
      open: boolean;
      onCancel: () => void;
      onAccept: (attributes: LegalAttributes) => void;
      acceptLoading: boolean;
      acceptError?: string;
    };

export function LegalDialog({ open, document, ...props }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, dispatch] = useReducer(reducer, initialState);
  const dialogContentRef = useRef(null);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchLatestEula() {
      try {
        dispatch({ type: 'LOAD' });

        const markdown = await getLegalMarkdown(document, {
          signal: abortController.signal,
        });

        dispatch({ type: 'SUCCESS', data: { markdown } });
      } catch (err) {
        if (err instanceof DOMException && err.name === 'AbortError') {
          dispatch({ type: 'ABORT' });
          return;
        }

        dispatch({
          type: 'FAILURE',
          data: {
            error: LL.fetchError(
              err instanceof Error ? err.message : 'something went wrong'
            ),
          },
        });
      }
    }

    open ? fetchLatestEula() : abortController.abort();

    return () => abortController.abort();
  }, [open, document]);

  function onScroll() {
    if (!dialogContentRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;

    if (Math.ceil(scrollTop + clientHeight) >= Math.floor(scrollHeight)) {
      dispatch({ type: 'READ' });
    }
  }

  function Content() {
    switch (state.status) {
      case 'idle': {
        return null;
      }
      case 'loading': {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="calc(100% - 48px)"
          >
            <CircularProgress />
          </Box>
        );
      }
      case 'success':
      case 'refreshing': {
        return (
          <Markdown options={{ wrapper: 'article' }}>
            {state.markdown.body}
          </Markdown>
        );
      }
      // TODO: provide action to retry fetch
      case 'error': {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="calc(100% - 48px)"
          >
            <Alert severity="error">{state.error}</Alert>
          </Box>
        );
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={props.variant === 'readonly' ? props.onClose : undefined}
      maxWidth="md"
      fullScreen={isMobile}
      PaperProps={{ sx: { height: '100%', width: '100%' } }}
    >
      <DialogContent ref={dialogContentRef} onScroll={onScroll}>
        <Box mb={2}>
          <BiampLogo width={100} aria-hidden={true} />
        </Box>
        <Content />
      </DialogContent>
      {props.variant === 'readonly' ? (
        <DialogActions>
          <Button onClick={props.onClose} variant="contained" color="primary">
            {LL.close}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions
          sx={{
            bgcolor: 'grey.700',
            flexDirection: 'column',
            px: 3,
            py: 3,
          }}
        >
          <Collapse in={!!props.acceptError} sx={{ width: '100%' }}>
            <Alert severity="error" sx={{ mb: 2 }}>
              {LL.acceptError(props.acceptError ?? '')}
            </Alert>
          </Collapse>
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography fontWeight="bold">{LL.acceptText}</Typography>
            <Box display="flex" gap={1}>
              <Button
                color="primary"
                onClick={props.onCancel}
                variant="outlined"
              >
                {LL.cancel}
              </Button>
              <LoadingButton
                loading={props.acceptLoading}
                disabled={state.status !== 'success' || !state.read}
                onClick={
                  state.status === 'success'
                    ? () => props.onAccept(state.markdown.attributes)
                    : undefined
                }
                color="primary"
                variant="contained"
              >
                {LL.accept}
              </LoadingButton>
            </Box>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}
