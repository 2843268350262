import { ApolloError } from '@apollo/client';
import { Divider, Stack, Typography } from '@mui/material';
import locale from '../../locale';

const LL = locale.errorPage;

type ErrorPageDetailsProps = {
  message: string;
  sentryId?: string;
  code?: string;
};

export function ErrorPageDetails({
  message,
  code,
  sentryId,
}: ErrorPageDetailsProps) {
  if (!message && !code && !sentryId) {
    return null;
  }

  return (
    <Stack
      divider={<Divider orientation="vertical" flexItem />}
      spacing={1}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      color="text.secondary"
      bgcolor="background.paper"
      borderRadius={1}
      p={2}
      mt={1}
    >
      {message && (
        <Typography variant="subtitle2" fontWeight={400} fontFamily="monospace">
          <b>{LL.message}:</b> {message}
        </Typography>
      )}
      {sentryId && (
        <Typography variant="subtitle2" fontWeight={400} fontFamily="monospace">
          <b>{LL.sentryId}: </b> {sentryId}
        </Typography>
      )}
      {code && (
        <Typography variant="subtitle2" fontWeight={400} fontFamily="monospace">
          <b>{LL.code}:</b> {code}
        </Typography>
      )}
    </Stack>
  );
}

type ErrorPageDetailsFromApolloErrorProps = {
  error: ApolloError;
};

export function ErrorPageDetailsFromApolloError({
  error,
}: ErrorPageDetailsFromApolloErrorProps) {
  return (
    <>
      {error.networkError && (
        <ErrorPageDetails message={error.networkError.message} />
      )}
      {error.graphQLErrors.map(({ message, extensions }, index) => {
        const sentryId =
          typeof extensions?.sentry === 'string'
            ? extensions?.sentry
            : undefined;
        const code =
          typeof extensions?.code === 'string' ? extensions?.code : undefined;

        return (
          <ErrorPageDetails
            key={index}
            message={message}
            sentryId={sentryId}
            code={code}
          />
        );
      })}
    </>
  );
}
