import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { returnToKey } from './config';
import { parseURL } from './utils';

export function getReturnToSearch(url: URL) {
  return new URLSearchParams({ [returnToKey]: url.href });
}

export function useReturnTo() {
  const url = useSearchParams()[0].get(returnToKey) ?? '';
  const returnTo = useMemo(() => parseURL(url), [url]);
  const returnToSearch = useMemo(
    () => returnTo && getReturnToSearch(returnTo),
    [returnTo]
  );
  return { returnTo, returnToSearch };
}
