export default {
  forms: {
    email: {
      errors: {
        required: 'Email required',
        invalid: 'Invalid email address',
      },
    },
    password: {
      errors: {
        required: 'Password required',
        invalid: 'At least 6 characters are required',
      },
    },
  },
  pages: {
    register: {
      header: 'Register a new account',
      googleButton: 'Register with Google',
      microsoftButton: 'Register with Microsoft',
      appleButton: 'Register with Apple',
      divider: 'or register with email',
      form: {
        name: {
          label: 'Your Name',
          required: 'Name required',
        },
        password: {
          label: 'Password',
          required: 'Password required',
        },
        email: {
          label: 'Email',
          errors: {
            required: 'Email required',
            invalid: 'Invalid email address',
            notAvailable: 'Email is not available',
          },
        },
        legalLink: {
          text: 'I have read and accept the',
          link: 'Terms of Service and Privacy Policy',
        },
        submitButton: 'Register',
      },
      error:
        'Something went wrong. Failed to register, please try again or check back later',
      signInLink: {
        text: 'Already have an account?',
        link: 'Sign in',
      },
      profileDetailsDialog: {
        title: 'Complete profile',
        description:
          'In order to register you need to verify the following fields.',
        form: {
          name: {
            label: 'Name',
            required: 'Name required',
          },
          email: {
            label: 'Email',
          },
        },
        submit: 'Register',
        cancel: 'Go back',
      },
    },
    signIn: {
      header: 'Sign in to your account',
      googleButton: 'Sign in with Google',
      microsoftButton: 'Sign in with Microsoft',
      appleButton: 'Sign in with Apple',
      emailButton: 'Sign in with email',
      oauth: {
        errors: {
          notFound:
            "We couldn't find an associated account. Please make sure to use the same sign-in method as used when registering",
        },
      },
      or: 'or',
      form: {
        email: {
          label: 'Email',
          requiredError: 'Email required',
          invalidError: 'Invalid email address',
        },
        rememberMe: {
          label: 'Remember my email',
        },
        password: {
          label: 'Password',
          required: 'Password required',
        },
        errors: {
          notFound: 'Incorrect email or password',
        },
      },
      error:
        'Something went wrong. Failed to sign in, please try again or check back later',
      submitButton: 'Sign in',
      registerLink: {
        text: "Don't have an account?",
        link: 'Register',
      },
      forgotPassword: 'Forgot password?',
    },
    profile: {
      title: 'Profile',
      signOut: 'Sign out',
      superAdmin: 'Super admin',
      organizationList: {
        hiWithName: (name: string) => `Hi ${name}!`,
        hi: 'Hi!',
        readyToGetToWork: 'Ready to get to work?',
        description:
          'Create a new organization or join an existing one. Open the app to book a desk!',
        verifyEmail: {
          text: 'Please verify your email to secure your account! No such email?',
          link: 'Resend the link',
        },
        errorTitle: 'Could not fetch organizations',
        errorText:
          'Something went wrong fetching your profile and organizations. Try signing out and signing back in again.',
        addOrganizationButton: {
          add: 'Add organization',
          join: 'Join organization',
          create: 'Create organization',
        },
        organizationCard: {
          awaitingApproval: 'Awaiting approval from admin',
          verifyEmail: 'Email verification needed',
          dashboardButton: 'Admin dashboard',
          joinButton: 'Accept',
          declineButton: 'Decline',
          expired: 'Invitation has expired',
          alerts: {
            JoinOrganizationMutation: {
              success: 'Invite accepted',
              failure: 'Could not join organization',
            },
            DeclineInvitationMutation: {
              success: 'Invite declined',
              failure: 'Could not decline invite',
            },
          },
        },
      },
      createOrganization: {
        title: 'Create organization',
        submitButton: 'Get started',
        signOut: 'Sign out',
        alreadyHaveOrg: 'Already have an organization?',
        joinOrganizationLink: 'Join an organization',
        alerts: {
          failure: 'Could not create organization',
        },
        name: {
          label: 'Organization name',
          helperText: 'The name of your organization.',
          errors: {
            required: 'Organization name required',
          },
        },
        domain: {
          helperText: 'The ID used to access your organization.',
          label: 'Organization ID',
          errors: {
            required: 'Organization ID required',
            notAvailable: 'This organization ID is not available',
            alphanumericValidation:
              'Organization ID can only contain alphanumeric characters',
          },
        },
      },
      joinOrganization: {
        title: 'Join organization',
        domain: {
          label: 'Organization ID',
          helperText: 'Find the ID in the email invite or ask an admin.',
          errors: {
            required: 'Organization ID required',
            alphanumericValidation:
              'Organization ID can only contain alphanumeric characters',
            notFound: 'Organization not found',
            alreadyMember: 'You are already a member of this organization',
            banned: 'You are banned from this organization',
            pending: "You've already requested to join this organization",
            verifyEmail:
              'Please verify your email before requesting to join an organization',
          },
        },
        submitButton: 'Ask to join',
        missingOrg: "Don't have an organization?",
        createOrganizationLink: 'Create an organization',
        readyToGetToWork: 'Ready to get to work?',
        hiWithName: (name: string) => `Hi ${name}!`,
        hi: 'Hi!',
        alerts: {
          failure: 'Could not join organization',
        },
      },
    },
    resetPassword: {
      header: 'Set a new password',
      alerts: {
        success: 'Password has been updated.',
        passwordsDoesNotMatch: 'The two entered passwords do not match.',
        error: 'Something went wrong.',
      },
      buttons: {
        setPasswordButton: 'Set password',
      },
      password: {
        label: 'New password',
        required: 'New password required',
      },
      repeatedPassword: {
        label: 'Repeat password',
        required: 'Repeat password required',
      },
    },
    forgotPassword: {
      header: 'Forgot your password?',
      description: 'Send a password reset link to your email.',
      backToSignIn: {
        text: 'Remember your password?',
        link: 'Sign in',
      },
      form: {
        email: {
          label: 'Email',
        },
        submitButton: 'Send password reset link',
      },
      alerts: {
        error: 'The organization and/or email does not exist.',
      },
      confirmResetSent: {
        header: 'A link to reset the password has been sent to your email',
        description:
          "Check your email and press the link to reset password. Haven't received a link? Check your spam filter or re-send the link.",
        alerts: {
          resentEmailSuccess: 'A new reset password email has been sent!',
          error: 'Something went wrong.',
        },
        buttons: {
          resendButton: {
            default: 'Re-send my password reset link',
            sent: 'Sent!',
          },
        },
      },
    },

    verifyEmail: {
      heading: 'Verify your email',
      verified: 'Thank you, your email has been verified',
      expired: 'Your token has expired, use the form below to get a new one',
      failure: 'Failed to verify email',
      verifyResent: (email: string) =>
        `A new invitation email has been sent to: ${email}`,
      resetFailure: (email: string) =>
        `Tried sending email to: ${email}, but it could not be delivered`,
      form: {
        domain: {
          requiredError: 'Domain required',
          invalidError: 'Invalid domain',
          placeholder: 'domain',
        },
        email: {
          label: 'Your Email',
        },
      },
      buttons: {
        login: 'Go to login',
        resendVerificationEmail: 'Send verification email',
      },
      alerts: {
        verificationError: 'Unable to verify email',
        resendVerificationLinkError: 'Unable to resend email',
      },
    },
    invite: {
      signInOrRegister: 'Sign in or register to join organization',
      emailMismatchError: {
        title: 'Invitation email does not match',
        description: (profileEmail: string, inviteEmail: string) =>
          `Your currently signed in with ${profileEmail} however this invite was intended for ${inviteEmail}.`,
        buttons: {
          signOut: 'Sign out',
          profile: 'Go to profile',
        },
      },
    },
    activate: {
      title: 'Select organization',
      description: 'Pick an organization for your Overview Screen.',
      notAllowed: 'Requires admin permission',
      button: 'Admin dashboard',
    },
    landing: {
      title: 'Evoko Workplace',
      subtitle:
        'Biamp Evoko product enable flexible hardware and software solutions for a frictionless worklife.',
      register: 'Register',
      signIn: 'Sign in',
      profile: 'Go to profile',
    },
  },
  components: {
    pageLogoHeader: {
      home: 'Home',
    },
  },
};
