import type { ApolloError } from '@apollo/client';
import locale from '../locale';
import {
  ErrorPage,
  ErrorPageDescription,
  ErrorPageDetailsFromApolloError,
  ErrorPageTitle,
} from './ErrorPage';

const LL = locale.profileQueryerrorPage;

type ProfileQueryErrorPageProps = {
  error?: ApolloError;
};

export function ProfileQueryErrorPage({ error }: ProfileQueryErrorPageProps) {
  return (
    <ErrorPage title={LL.title}>
      <ErrorPageTitle>{LL.title}</ErrorPageTitle>
      <ErrorPageDescription>{LL.description}</ErrorPageDescription>
      {error && <ErrorPageDetailsFromApolloError error={error} />}
    </ErrorPage>
  );
}
