export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const numericRegex = /^-?\d+$/;

export const alphanumericRegex = /^[0-9A-Z]+$/i;

export const uuidv4Regex =
  /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;

export const overviewActivationCodeRegex =
  /^(?:[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}|[0-9]{8})$/;

export const whitelistDomainRegex =
  /^(\s*[\w-]+(\.[\w-]+)+\s*)(,\s*[\w-]+(\.[\w-]+)+\s*)*$/;
