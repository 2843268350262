import { DateTime, Duration } from 'luxon';

export function fromDurationScalar(s: number) {
  return Duration.fromObject({ seconds: s });
}

export function fromOptDurationScalar(s?: number | null) {
  return s === null || s === undefined ? s : fromDurationScalar(s);
}

export function toDurationScalar(d: Duration): number {
  return d.as('seconds');
}

export function toOptDurationScalar(d?: Duration | null) {
  return d === null || d === undefined ? d : toDurationScalar(d);
}

export function fromDateTimeScalar(dt: string) {
  return DateTime.fromISO(dt).toUTC();
}

export function toDateTimeScalar(dt: DateTime) {
  return dt.toUTC().toISO();
}

export function fromOptDateTimeScalar(dt?: string | null) {
  return dt === null || dt === undefined ? dt : fromDateTimeScalar(dt);
}

export function toOptDateTimeScalar(dt?: DateTime | null) {
  return dt === null || dt === undefined ? dt : toDateTimeScalar(dt);
}

export function fromTimeOfDayScalarToDateTime(s: number) {
  const d = Duration.fromObject({ seconds: s })
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();
  return DateTime.fromObject(
    { hour: d.hours, minute: d.minutes, second: d.seconds },
    { zone: 'UTC' }
  );
}

export function fromOptionalTimeOfDayScalarToDateTime(s: number | null) {
  return s ? fromTimeOfDayScalarToDateTime(s) : null;
}

export function toTimeOfDayScalarFromDateTime(dt: DateTime) {
  const { hour, minute, second } = dt.toUTC();
  return Duration.fromObject({ hour, minute, second }).as('seconds');
}

export function toOptionalTimeOfDayScalarFromDateTime(dt: DateTime | null) {
  return dt ? toTimeOfDayScalarFromDateTime(dt) : null;
}
