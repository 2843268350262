import { BiampLogo } from '@evoko/components';
import { Box, Typography, Link } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Link as RouterLink } from 'react-router-dom';
import locale from '../locale';

const LL = locale.components.pageLogoHeader;

export function PageLogoHeader() {
  return (
    <Box
      component="header"
      display="flex"
      justifyContent="center"
      mx="auto"
      py={{ xs: 3, md: 6 }}
      px={{ xs: 2, sm: 3 }}
    >
      <Link
        component={RouterLink}
        to="/"
        color="inherit"
        display="block"
        maxWidth={100}
        width="100%"
      >
        <Typography component="span" style={visuallyHidden}>
          {LL.home}
        </Typography>
        <BiampLogo aria-hidden />
      </Link>
    </Box>
  );
}
