import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  type ApolloLink,
  type DefaultOptions,
  type NormalizedCacheObject,
  type TypePolicies,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, type ErrorResponse } from '@apollo/client/link/error';
import { MockLink, type MockedResponse } from '@apollo/client/testing';
import { isPrimitive, keyOf } from '@evoko/utils';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import type { ApolloLinkErrorAction } from './ApolloProvider';
import {
  OVERVIEW_SESSION_DATA_KEY,
  SessionKey,
  USER_SESSION_DATA_KEY,
  getSessionData,
  getSessionDataAsync,
  invalidateAccessToken,
} from './auth';
import { getApiUrl } from './config';
import type { Invitation, Membership } from './generated/graphql';

export const CLIENT_META_HEADER_NAME = 'Evoko-Client';

export function getClientMetaHeaderValue() {
  return `web/${import.meta.env.VITE_RELEASE_SHA_SHORT ?? 'unknown'}`;
}

/**
 * Creates apollo link for adding access token to the authorization header and
 * handle the refresh token logic.
 **/
export const createAuthLink = (
  sessionKey: SessionKey,
  client: ApolloClient<NormalizedCacheObject>
) => {
  const innerAuthLink = setContext(async (_, context) => {
    const session = await getSessionDataAsync(sessionKey, client);

    // If there is no session, pass the request up the chain (unauthenticated request)
    if (!session) {
      return context;
    }

    // If there is a session, add it to the authorization header (authenticated request)
    return {
      ...context,
      headers: {
        ...context.headers,
        Authorization: `Bearer ${session.accessToken}`,
      },
    };
  });

  const innerErrorLink = onError(({ networkError, forward, operation }) => {
    // If the server returns a 401
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      const session = getSessionData(sessionKey);
      // ... and we have a session
      if (session) {
        // ... then invalidate the access token if we're not refreshing
        if (!session.refreshing) {
          invalidateAccessToken(sessionKey);
        }

        // ... and retry the request by sending it back up the chain (to `innerAuthLink`)
        // to invoke `getSessionDataAsync` and trigger (or await ongoing) token refresh
        return forward(operation);
      }
    }
  });

  return from([innerErrorLink, innerAuthLink]);
};

type CreateEulaLinkOptions = {
  linkErrorDispatch: React.Dispatch<ApolloLinkErrorAction>;
};

/**
 * Creates apollo link for handling eula errors and dispatching
 * the error to be available in react state via `linkErrorDispatch`.
 */
export const createEulaLink = ({
  linkErrorDispatch,
}: CreateEulaLinkOptions) => {
  let isEulaError = false;
  let pendingRequests: { resolve: () => unknown; reject: () => unknown }[] = [];

  function handlePendingRequests(action: 'resolve' | 'reject') {
    switch (action) {
      case 'resolve':
        pendingRequests.forEach(({ resolve }) => resolve());
        break;
      case 'reject':
        pendingRequests.forEach(({ reject }) => reject());
    }
    pendingRequests = [];
  }

  const innerRequestLink = setContext((request, context) => {
    // If there already is a eula error, append the request to the pending queue.
    // We make an exception for `UpdateProfileEula` since it's needed when accepting
    // the new EULA version.
    if (isEulaError && request?.operationName !== 'UpdateProfileEula') {
      return new Promise((resolve, reject) => {
        pendingRequests.push({
          resolve: () => resolve(context),
          reject: () => reject({}),
        });
      });
    }

    return context;
  });

  const innerErrorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        const code = graphQLError.extensions?.code;

        if (code === 'eulaNotAccepted') {
          if (!isEulaError) {
            isEulaError = true;

            linkErrorDispatch({
              type: 'ADD',
              data: {
                error: graphQLError,
                resolve: () => {
                  // If a new eula version is successfully accepted then the access token must
                  // be refreshed so that it contains the new version (hence invalidating)
                  invalidateAccessToken(USER_SESSION_DATA_KEY);
                  isEulaError = false;
                  handlePendingRequests('resolve');
                  linkErrorDispatch({ type: 'CLEAR', data: { code } });
                },
                reject: () => {
                  isEulaError = false;
                  handlePendingRequests('reject');
                  linkErrorDispatch({ type: 'CLEAR', data: { code } });
                },
              },
            });
          }

          return forward(operation);
        }
      }
    }
  });

  return from([innerErrorLink, innerRequestLink]);
};

type CreateSentryLinkOptions = {
  /** If returns `true`, the error will not be reported to sentry. */
  ignoreLoggingErrorToSentry?: (
    error: Pick<ErrorResponse, 'operation' | 'graphQLErrors'>
  ) => boolean;
};

/**
 * Creates apollo link for sending errors to sentry.
 *
 * NOTE: we're not sending any variables for the operation to sentry as it could
 * contain sensitive data which requires careful marsking based on `@sensitive`
 * directive. Consider adding it if masking can be done with confidence and
 * without too much overhead.
 */
export const createSentryLink = (options: CreateSentryLinkOptions = {}) =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (options?.ignoreLoggingErrorToSentry?.({ operation, graphQLErrors })) {
      return;
    }

    const scope = new Sentry.Scope();
    scope.setLevel('debug');
    scope.setTag('operation_name', operation.operationName);
    scope.setExtra('operation_body', operation.query.loc?.source.body);

    for (const definition of operation.query.definitions) {
      if (definition.kind === 'OperationDefinition') {
        scope.setTag('operation_type', definition.operation);
        break;
      }
    }

    const context = operation.getContext();
    if (context?.response instanceof Response) {
      scope.setTag('request_id', context.response.headers.get('X-Request-Id'));
      scope.setTag('response_status', context.response.status);
    }

    if (networkError) {
      Sentry.captureException(networkError, scope);
    }

    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        // Extend the default fingerprint for better operation/error grouping
        const fingerprint = ['{{ default }}', operation.operationName];

        for (const [key, value] of Object.entries(error.extensions)) {
          if (
            typeof value === 'string' &&
            (key === 'code' || key === 'field')
          ) {
            fingerprint.push(value);
          }
          if (isPrimitive(value)) {
            scope.setTag(`error_extension_${key}`, value);
          }
        }
        scope.setExtra('error', error);
        scope.setFingerprint(fingerprint);

        // NOTE: as of writing the upstream typing for `graphQLErrors` seems wrong
        // as its elements is plain objects and not instances of `GraphQLError`...
        // hence generating new a `GraphQLError` to make it more helpful to sentry
        // https://github.com/apollographql/apollo-client/issues/11168
        Sentry.captureException(
          new GraphQLError(error.message, {
            extensions: error.extensions,
            path: error.path,
          }),
          scope
        );
      }
    }
  });

const typePolicies: TypePolicies = {
  Invitation: {
    keyFields: [keyOf<Invitation>('orgId'), keyOf<Invitation>('email')],
  },
  Membership: {
    keyFields: [keyOf<Membership>('orgId'), keyOf<Membership>('userId')],
  },
};

const anonClientDefaultOptions: DefaultOptions = {
  mutate: { fetchPolicy: 'no-cache' },
  query: { fetchPolicy: 'no-cache' },
};

/** An anonymous apollo client that can be used for unauthenticated requests. */
export const anonClient = (() => {
  const httpLink = createHttpLink({
    uri: getApiUrl().href,
    headers: { [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue() },
  });
  const sentryLink = createSentryLink();

  return new ApolloClient({
    link: from([sentryLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
    defaultOptions: anonClientDefaultOptions,
  });
})();

type CreateUserClientOptions = CreateEulaLinkOptions & CreateSentryLinkOptions;

/** Creates apollo client that should be used by users for most requests. */
export function createUserClient({
  linkErrorDispatch,
}: CreateUserClientOptions) {
  const httpLink = createHttpLink({
    uri: getApiUrl().href,
    headers: { [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue() },
  });
  const authLink = createAuthLink(USER_SESSION_DATA_KEY, anonClient);
  const eulaLink = createEulaLink({ linkErrorDispatch });
  const sentryLink = createSentryLink();

  return new ApolloClient({
    link: from([sentryLink, eulaLink, authLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
  });
}

type CreateOverviewClientOptions = CreateSentryLinkOptions;

/** Creates apollo client that should be used by the overview screen. */
export function createOverviewClient({
  ignoreLoggingErrorToSentry,
}: CreateOverviewClientOptions) {
  const httpLink = createHttpLink({
    uri: getApiUrl().href,
    headers: { [CLIENT_META_HEADER_NAME]: getClientMetaHeaderValue() },
  });
  const authLink = createAuthLink(OVERVIEW_SESSION_DATA_KEY, anonClient);
  const sentryLink = createSentryLink({ ignoreLoggingErrorToSentry });

  return new ApolloClient({
    link: from([sentryLink, authLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
  });
}

// Creates an apollo client that can be used for mocking requets.
// Inspired by https://github.com/apollographql/apollo-client/blob/main/src/testing/core/mocking/mockClient.ts
// but composed our own to allow mocking errors in the result
export function createMockClient<TData>({
  responses,
  link,
}: {
  responses: MockedResponse<TData>[];
  link?: ApolloLink;
}) {
  return new ApolloClient({
    link: (link
      ? from([link, new MockLink(responses)])
      : new MockLink(responses)
    ).setOnError((error) => {
      throw error;
    }),
    cache: new InMemoryCache({ typePolicies, addTypename: false }),
    defaultOptions: anonClientDefaultOptions,
  });
}
