import { getAdminUrl, isAllowed, Permission } from '@evoko/api';
import {
  OrganizationCard,
  OrganizationCardActions,
  OrganizationCardDescription,
  OrganizationCardTitle,
  Page,
  ProfileQueryErrorPage,
  VisitorManagementIllustration,
} from '@evoko/components';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProfileQuery } from '../../hooks';
import locale from '../../locale';

const LL = locale.pages.activate;

function getAdminRedirectUrl(domain: string, userCode?: string) {
  const url = getAdminUrl(`${domain}/overview-screens`);
  if (userCode) {
    url.searchParams.append('code', userCode);
  }

  url.searchParams.append('prompt', String(true));

  return url;
}

/** Redirect page in Overview Screen activation flow. */
export default function ActivatePage() {
  const userCode = useSearchParams()[0].get('code') ?? undefined;
  const { profile, loading, error } = useProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const memberships = useMemo(
    () =>
      profile
        ? [...profile.memberships].sort((a, b) =>
            a.orgName.localeCompare(b.orgName)
          )
        : [],
    [profile]
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (error || !profile) {
    return <ProfileQueryErrorPage error={error} />;
  }

  if (
    memberships.length === 1 &&
    isAllowed(memberships[0].role, Permission.AuthorizeOverview)
  ) {
    const domain = memberships[0].organization?.domain ?? '';
    window.location.assign(getAdminRedirectUrl(domain, userCode));
    return null;
  }

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: 'flex-start', md: 'center' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={410}
        py={3}
        gap={1}
        px={{ xs: 2, sm: 3 }}
        mx="auto"
        alignItems="center"
      >
        <VisitorManagementIllustration height={140} width={140} />
        <Typography variant="h1">{LL.title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {LL.description}
        </Typography>
        <Box display="flex" flexDirection="column" width="100%" gap={2} mt={1}>
          {memberships.map(
            ({ orgId, orgName, orgLogoUrl, role, organization }) => {
              const allowed = isAllowed(role, Permission.AuthorizeOverview);
              const domain = organization?.domain;
              return (
                <OrganizationCard key={orgId} logoUrl={orgLogoUrl ?? undefined}>
                  <OrganizationCardTitle>{orgName}</OrganizationCardTitle>
                  {!allowed && (
                    <OrganizationCardDescription color="warning.main">
                      {LL.notAllowed}
                    </OrganizationCardDescription>
                  )}
                  {allowed && domain && (
                    <OrganizationCardActions>
                      <Button
                        variant="contained"
                        href={getAdminRedirectUrl(domain, userCode).href}
                        fullWidth
                      >
                        {LL.button}
                      </Button>
                    </OrganizationCardActions>
                  )}
                </OrganizationCard>
              );
            }
          )}
        </Box>
      </Box>
    </Page>
  );
}
