/** URL param key used for redirecting to given URL upon successful sign in. */
export const returnToKey = 'return_to';

export function getPublicUrl(path = '') {
  return new URL(path, window.location.origin);
}

export function getPublicUrlWithReturnTo(path: string, returnTo: URL) {
  const url = getPublicUrl(path);
  url.searchParams.append(returnToKey, returnTo.href);
  return url;
}

export function getOverviewUrl(path = '') {
  const base = getPublicUrl('/overview/');
  // Make sure the provided path is relative (otherwise `/overview/` is overwritten).
  path = path.charAt(0) === '/' ? path.substring(1) : path;
  return new URL(path, base);
}

export function getAdminUrl(path = '') {
  const base = getPublicUrl('/admin/');
  // Make sure the provided path is relative (otherwise `/admin/` is overwritten).
  path = path.charAt(0) === '/' ? path.substring(1) : path;
  return new URL(path, base);
}

export function getSuperAdminUrl(path = '') {
  const base = getPublicUrl('/super-admin/');
  // Make sure the provided path is relative (otherwise `/super-admin/` is overwritten).
  path = path.charAt(0) === '/' ? path.substring(1) : path;
  return new URL(path, base);
}

export function getBackendUrl(path = '') {
  return getPublicUrl(path);
}

export function getMediaApiUrl() {
  return getBackendUrl('/api/v1/media');
}

export function getApiUrl() {
  return getBackendUrl('/graphql/');
}

export function getStorageUrl(path = '') {
  const base = (() => {
    switch (window.location.hostname) {
      case 'evoko.app':
        return 'https://evokoprinceprod.blob.core.windows.net';
      case 'staging.evoko.app':
        return 'https://evokoprincestaging.blob.core.windows.net';
      default:
        return 'https://evokoprincedev.blob.core.windows.net';
    }
  })();
  return new URL(path, base);
}

export function isProduction() {
  return window.location.hostname === 'evoko.app';
}
