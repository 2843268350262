export default function useSavedCredentials() {
  const setCredentials = (credentials: { email: string; domain: string }) => {
    localStorage.setItem('credentials', JSON.stringify(credentials));
  };

  const clearCredentials = () => {
    localStorage.removeItem('credentials');
  };

  try {
    const rawStorageItem = localStorage.getItem('credentials');

    if (typeof rawStorageItem === 'string') {
      return [JSON.parse(rawStorageItem), setCredentials, clearCredentials];
    }
  } catch (error) {
    console.warn(error);
  }

  return [{}, setCredentials, clearCredentials];
}
