import { useApolloClient } from '@apollo/client';
import { useAuth } from '@evoko/api';
import {
  ErrorPage,
  ErrorPageActions,
  ErrorPageDescription,
  ErrorPageTitle,
  ProfileQueryErrorPage,
} from '@evoko/components';
import { Button, LinearProgress } from '@mui/material';
import {
  Navigate,
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useProfileQuery } from '../../hooks';
import locale from '../../locale';
import type { SignInLocationState } from '../SignInPage';

const LL = locale.pages.invite;

export default function InvitePage() {
  const inviteEmail = useSearchParams()[0].get('email');
  const navigate = useNavigate();
  const { authenticated, signOut } = useAuth();
  const client = useApolloClient();
  const { profile, loading, error } = useProfileQuery({ skip: !authenticated });
  const state: SignInLocationState = inviteEmail
    ? { email: inviteEmail, successMessage: LL.signInOrRegister }
    : {};

  if (authenticated) {
    if (loading) {
      return <LinearProgress />;
    }

    if (error) {
      return <ProfileQueryErrorPage error={error} />;
    }

    if (inviteEmail && profile) {
      // If the users email match the email of the invitation, redirect to `/profile`
      if (inviteEmail === profile.email) {
        return <Navigate to="/profile" replace />;
      }
      // Otherwise display mismatch to user
      return (
        <ErrorPage title={LL.emailMismatchError.title}>
          <ErrorPageTitle>{LL.emailMismatchError.title}</ErrorPageTitle>
          <ErrorPageDescription>
            {LL.emailMismatchError.description(profile.email, inviteEmail)}
          </ErrorPageDescription>
          <ErrorPageActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                signOut(client);
                navigate('/signin', { state });
              }}
            >
              {LL.emailMismatchError.buttons.signOut}
            </Button>
            <Button
              variant="contained"
              color="primary"
              to="/profile"
              component={RouterLink}
            >
              {LL.emailMismatchError.buttons.profile}
            </Button>
          </ErrorPageActions>
        </ErrorPage>
      );
    }
  }

  return <Navigate to="/signin" state={state} replace />;
}
