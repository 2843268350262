import { useApolloClient } from '@apollo/client';
import { useAuth } from '@evoko/api';
import { Navigate } from 'react-router-dom';

export default function SignOutPage() {
  const client = useApolloClient();
  const { signOut } = useAuth();

  signOut(client);

  return <Navigate to="signin" replace />;
}
