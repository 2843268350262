/* eslint-disable react/no-children-prop */
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'markdown-to-jsx';

type Props = React.ComponentProps<typeof ReactMarkdown>;

export function Markdown({ children, options }: Props) {
  return (
    <ReactMarkdown
      children={children}
      options={{
        ...options,
        overrides: {
          h1: ({ children }) => <Typography children={children} variant="h1" />,
          h2: ({ children }) => (
            <Typography children={children} variant="h2" mt={3} />
          ),
          h3: ({ children }) => (
            <Typography children={children} variant="h3" mt={2} />
          ),
          h4: ({ children }) => (
            <Typography children={children} variant="h4" mt={1} />
          ),
          p: ({ children }) => (
            <Typography children={children} color="grey.300" my={1} />
          ),
          li: ({ children }) => (
            <Typography
              children={children}
              component="li"
              color="grey.300"
              my={0.5}
            />
          ),
          a: ({ children, href }) => (
            <Link children={children} href={href} color="inherit" />
          ),
          ...options?.overrides,
        },
      }}
    />
  );
}
