import {
  gql,
  ApolloClient,
  NormalizedCacheObject,
  ApolloError,
} from '@apollo/client';
import { zStringJson } from '@evoko/utils';
import { captureException } from '@sentry/react';
import { z } from 'zod';
import type {
  AuthenticateMutation,
  AuthenticateMutationVariables,
  AuthenticateWithOidcMutation,
  AuthenticateWithOidcMutationVariables,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  RegisterWithEmailMutation,
  RegisterWithEmailMutationVariables,
  RegisterWithOidcMutation,
  RegisterWithOidcMutationVariables,
} from './generated/graphql';
import { ErrorCode } from './errors';
import { decodeJwt } from 'jose';

export const USER_SESSION_DATA_KEY = 'session';
export const OVERVIEW_SESSION_DATA_KEY = 'overviewSession';
export const SESSION_TOKEN_KEY = 'token'; // deprecated

export type SessionKey =
  | typeof USER_SESSION_DATA_KEY
  | typeof OVERVIEW_SESSION_DATA_KEY;

const sessionDataSchema = z.object({
  subjectId: z.string(),
  refreshToken: z.string(),
  accessToken: z.nullable(z.string()),
  accessTokenExpiresAt: z.number(),
  refreshing: z.boolean(),
});

/**
 * @deprecated use {@link sessionDataSchema}
 */
const oldSessionDataSchema = z.object({
  refreshToken: z.string(),
  accessToken: z.nullable(z.string()),
  accessTokenExpiresAt: z.number(),
  refreshing: z.boolean(),
});

export type SessionData = z.infer<typeof sessionDataSchema>;

/** Updates access token, refresh token and meta data in local storage. */
export function setSessionData(
  sessionKey: SessionKey,
  {
    subjectId,
    refreshToken,
    accessToken,
    expiresIn,
    now,
  }: {
    subjectId: string;
    refreshToken: string;
    accessToken: string;
    expiresIn: number;
    /** For unit testing, fallsback to `Date.now()` if omitted. */
    now?: number;
  }
) {
  const accessTokenExpiresAt = (now ?? Date.now()) + expiresIn * 1000 - 5000; // subtract 5 sec to better guard against timing issues
  const session: SessionData = {
    subjectId,
    refreshToken,
    accessToken,
    accessTokenExpiresAt,
    refreshing: false,
  };
  localStorage.setItem(sessionKey, JSON.stringify(session));
  return session;
}

/**
 * Parses a serialized `SessionData` object and validates key/value pairs.
 * @param data the serialized `SessionData` object to be parsed.
 * @returns `null` if parsing or validation fails.
 */
export function parseSessionData(data: string): SessionData | null {
  try {
    const result = zStringJson.pipe(sessionDataSchema).safeParse(data);
    if (result.success) {
      return result.data;
    }

    // NOTE: below is added as a temporary fallback for adding `subjectId` to the
    // `SessionData` object in a backwards compatible way without having to sign
    // out the user. Ideally we should treat the access token as a opaque string
    // and not attempt to parse it. Remove this fallback once it has been in
    // production for 90 days (the lifecycle of a refresh token).
    if (
      result.error.errors.some(
        (e) => e.path.includes('subjectId') && e.message === 'Required'
      )
    ) {
      const oldSession = zStringJson.pipe(oldSessionDataSchema).parse(data);
      if (oldSession.accessToken) {
        const { sub } = decodeJwt(oldSession.accessToken);
        if (!sub) {
          throw new Error('no sub in access token');
        }
        const [_, subjectId] = sub.split(':');
        z.string().uuid().parse(subjectId);
        return { ...oldSession, subjectId };
      }
    }
    throw result.error;
  } catch (error) {
    captureException(error);
    return null;
  }
}

/** Retrives access token, refresh token and meta data from local storage. */
export function getSessionData(sessionKey: SessionKey): SessionData | null {
  const sessionString = localStorage.getItem(sessionKey);

  if (!sessionString) {
    return null;
  }

  return parseSessionData(sessionString);
}

/** Removes access token, refresh token and meta data from local storage. */
export function removeSessionData(sessionKey: SessionKey) {
  localStorage.removeItem(sessionKey);
  localStorage.removeItem(SESSION_TOKEN_KEY); // deprecated
}

/** Removes access token and sets expiration timestamp to `0` in local storage. */
export function invalidateAccessToken(sessionKey: SessionKey) {
  const currentSession = getSessionData(sessionKey);

  if (currentSession) {
    const session: SessionData = {
      ...currentSession,
      accessToken: null,
      accessTokenExpiresAt: 0,
    };

    localStorage.setItem(sessionKey, JSON.stringify(session));
    localStorage.removeItem(SESSION_TOKEN_KEY); // deprecated
  }
}

/** Sets `refreshing` flag to `true` in local storage. */
export function setSessionDataRefreshing(
  sessionKey: SessionKey,
  refreshing: boolean
) {
  const currentSession = getSessionData(sessionKey);

  if (currentSession) {
    const session: SessionData = { ...currentSession, refreshing };
    localStorage.setItem(sessionKey, JSON.stringify(session));
  }
}

export function isOverviewAuthenticated() {
  return !!getSessionData(OVERVIEW_SESSION_DATA_KEY);
}

export function setOverviewSession(data: {
  subjectId: string;
  refreshToken: string;
  accessToken: string;
  expiresIn: number;
}) {
  setSessionData(OVERVIEW_SESSION_DATA_KEY, data);
}

export function removeOverviewSession() {
  removeSessionData(OVERVIEW_SESSION_DATA_KEY);
}

export const M_REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      refreshToken
      accessToken
      expiresIn
    }
  }
`;

export async function refreshToken({
  refreshToken,
  client,
}: {
  refreshToken: string;
  client: ApolloClient<NormalizedCacheObject>;
}) {
  const { data } = await client.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: M_REFRESH_TOKEN,
    variables: { refreshToken },
  });

  const payload = data?.refreshToken;

  if (!payload) {
    throw new Error('no payload');
  }

  return payload;
}

export const M_AUTHENTICATE = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      refreshToken
      accessToken
      expiresIn
      subject {
        ... on User {
          id
        }
      }
    }
  }
`;

type AuthenticateWithEmailParams = {
  client: ApolloClient<NormalizedCacheObject>;
} & AuthenticateMutationVariables;

export async function authenticateWithEmail({
  email,
  password,
  client,
}: AuthenticateWithEmailParams) {
  const { data } = await client.mutate<
    AuthenticateMutation,
    AuthenticateMutationVariables
  >({ mutation: M_AUTHENTICATE, variables: { email, password } });

  const payload = data?.authenticate;

  if (!payload) {
    throw new Error('no payload');
  }

  if (payload.subject.__typename !== 'User') {
    throw new Error('expected subject to be user');
  }

  return {
    subjectId: payload.subject.id,
    refreshToken: payload.refreshToken,
    accessToken: payload.accessToken,
    expiresIn: payload.expiresIn,
  };
}

export const M_REGISTER_WITH_EMAIL = gql`
  mutation RegisterWithEmail($input: RegisterInput!) {
    register(input: $input) {
      refreshToken
      accessToken
      expiresIn
      subject {
        ... on User {
          id
        }
      }
    }
  }
`;

type RegisterWithEmailParams = {
  client: ApolloClient<NormalizedCacheObject>;
} & RegisterWithEmailMutationVariables;

export async function registerWithEmail({
  input,
  client,
}: RegisterWithEmailParams) {
  const { data } = await client.mutate<
    RegisterWithEmailMutation,
    RegisterWithEmailMutationVariables
  >({ mutation: M_REGISTER_WITH_EMAIL, variables: { input } });

  const payload = data?.register;

  if (!payload) {
    throw new Error('no payload');
  }

  if (payload.subject.__typename !== 'User') {
    throw new Error('expected subject to be user');
  }

  return {
    subjectId: payload.subject.id,
    refreshToken: payload.refreshToken,
    accessToken: payload.accessToken,
    expiresIn: payload.expiresIn,
  };
}

export const M_AUTHENTICATE_WITH_OIDC = gql`
  mutation AuthenticateWithOIDC($idToken: String!) {
    authenticateWithIdToken(idToken: $idToken) {
      refreshToken
      accessToken
      expiresIn
      subject {
        ... on User {
          id
        }
      }
    }
  }
`;

type AuthenticateWithOIDCParams = {
  client: ApolloClient<NormalizedCacheObject>;
} & AuthenticateWithOidcMutationVariables;

export async function authenticateWithOIDC({
  idToken,
  client,
}: AuthenticateWithOIDCParams) {
  const { data } = await client.mutate<
    AuthenticateWithOidcMutation,
    AuthenticateWithOidcMutationVariables
  >({
    mutation: M_AUTHENTICATE_WITH_OIDC,
    variables: { idToken },
  });

  const payload = data?.authenticateWithIdToken;

  if (!payload) {
    throw new Error('no payload');
  }

  if (payload.subject.__typename !== 'User') {
    throw new Error('expected subject to be user');
  }

  return {
    subjectId: payload.subject.id,
    refreshToken: payload.refreshToken,
    accessToken: payload.accessToken,
    expiresIn: payload.expiresIn,
  };
}

export const M_REGISTER_WITH_OIDC = gql`
  mutation RegisterWithOIDC($input: RegisterWithOIDCInput!) {
    registerWithOIDC(input: $input) {
      refreshToken
      accessToken
      expiresIn
      subject {
        ... on User {
          id
        }
      }
    }
  }
`;

type RegisterWithOIDCParams = {
  client: ApolloClient<NormalizedCacheObject>;
} & RegisterWithOidcMutationVariables;

export async function registerWithOIDC({
  client,
  input,
}: RegisterWithOIDCParams) {
  const { data } = await client.mutate<
    RegisterWithOidcMutation,
    RegisterWithOidcMutationVariables
  >({ mutation: M_REGISTER_WITH_OIDC, variables: { input } });

  const payload = data?.registerWithOIDC;

  if (!payload) {
    throw new Error('no payload');
  }

  if (payload.subject.__typename !== 'User') {
    throw new Error('expected subject to be user');
  }

  return {
    subjectId: payload.subject.id,
    refreshToken: payload.refreshToken,
    accessToken: payload.accessToken,
    expiresIn: payload.expiresIn,
  };
}

/**
 * Works the same as `getSessionData` but will handle refresh of the access token
 * if needed.
 */
export const getSessionDataAsync = (() => {
  type PendingRequest = {
    resolve: (newSession: SessionData) => unknown;
    reject: () => unknown;
  };

  const pendingRequests: Record<SessionKey, PendingRequest[]> = {
    [USER_SESSION_DATA_KEY]: [],
    [OVERVIEW_SESSION_DATA_KEY]: [],
  };

  const handlePendingRequests = (
    sessionKey: SessionKey,
    data: { action: 'resolve'; newSession: SessionData } | { action: 'reject' }
  ) => {
    switch (data.action) {
      case 'resolve':
        pendingRequests[sessionKey].forEach(({ resolve }) =>
          resolve(data.newSession)
        );
        break;
      case 'reject':
        pendingRequests[sessionKey].forEach(({ reject }) => reject());
    }
    pendingRequests[sessionKey] = [];
  };

  // This should only fire when changes to local storage happens in *other*
  // browser tabs and is put here to handle pending request that may have
  // been piling up in the current tab while refresh happens in another
  window.addEventListener('storage', (e) => {
    if (
      (e.key === USER_SESSION_DATA_KEY ||
        e.key === OVERVIEW_SESSION_DATA_KEY) &&
      e.oldValue
    ) {
      const oldSession = parseSessionData(e.oldValue);
      const newSession = e.newValue ? parseSessionData(e.newValue) : null;

      // Refresh success (in another tab)
      // If `session.refreshing` went from `true` to `false` and the value of
      // the `accessToken` has changed we can assume the other tab successfully
      // refreshed the access token and that any pending requests in the current
      // tab can be resumed
      if (
        oldSession?.refreshing === true &&
        newSession?.refreshing === false &&
        newSession.accessToken !== null &&
        oldSession.accessToken !== newSession.accessToken
      ) {
        handlePendingRequests(e.key, { action: 'resolve', newSession });
        return;
      }

      // Refresh failure (in another tab)
      // If `session.refreshing` was `true` but `session is now `null` we can
      // assume `refreshToken` failed and that the users session data was cleared
      if (oldSession?.refreshing === true && e.newValue === null) {
        handlePendingRequests(e.key, { action: 'reject' });
      }
    }
  });

  return async (
    sessionKey: SessionKey,
    client: ApolloClient<NormalizedCacheObject>
  ) => {
    try {
      const session = getSessionData(sessionKey);
      // If there is no session, return null
      if (!session) {
        return null;
      }

      // If the access token is valid, return the current session
      if (session.accessTokenExpiresAt >= Date.now()) {
        return session;
      }

      // If the access token is expired and we're not refreshing, initiate refresh
      if (!session.refreshing) {
        setSessionDataRefreshing(sessionKey, true);
        const data = await refreshToken({
          client,
          refreshToken: session.refreshToken,
        });
        const newSession = setSessionData(sessionKey, {
          ...data,
          subjectId: session.subjectId,
        });
        handlePendingRequests(sessionKey, { action: 'resolve', newSession });
        return newSession;
      }

      // If refresh is already initiated, return promise and append to queue
      return new Promise<SessionData>((resolve, reject) => {
        pendingRequests[sessionKey].push({
          resolve: (newSession) => resolve(newSession),
          reject: () => reject({}),
        });
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        for (const { extensions } of error.graphQLErrors) {
          switch (extensions?.code) {
            case ErrorCode.RefreshTokenExpired:
            case ErrorCode.InvalidRefreshToken: {
              // Remove session data and reload browser tab to reset all
              // state (let react apps figure if and how to redirect)
              removeSessionData(sessionKey);
              window.location.reload();
              return null;
            }
          }
        }
      }
      setSessionDataRefreshing(sessionKey, false);
      handlePendingRequests(sessionKey, { action: 'reject' });
      captureException(error);
      throw error;
    }
  };
})();
