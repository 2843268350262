import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { gql, useMutation } from '@apollo/client';
import { getAdminUrl, getLegalMarkdown } from '@evoko/api';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from '../../../../generated/graphql';
import locale from '../../../../locale';
import slugify from 'slugify';
import { alphanumericRegex } from '@evoko/utils';
import { LegalCheckbox } from './components/LegalCheckbox';
import { Form } from '@evoko/components';
import {
  Box,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';

const M_CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      domain
    }
  }
`;

const LL = locale.pages.profile.createOrganization;

export function CreateOrganization() {
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, setValue, handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      domain: '',
      legal: false,
    },
  });
  const { isValid, isSubmitting } = formState;

  const [createOrganization, { loading }] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(M_CREATE_ORGANIZATION, {
    onCompleted: ({ createOrganization: { domain } }) => {
      window.location.assign(getAdminUrl(domain));
    },
    onError: () => {
      enqueueSnackbar(LL.alerts.failure, { variant: 'error' });
    },
  });

  const onSubmit = handleSubmit(async ({ legal: _, ...input }) => {
    const markdown = await getLegalMarkdown('ola');
    const ola = markdown.attributes.version.toString();
    await createOrganization({ variables: { input: { ...input, ola } } });
  });

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={{ sm: 'auto' }}>
      <Helmet>
        <title>{LL.title}</title>
      </Helmet>
      <Box>
        <IconButton
          component={Link}
          to=".."
          aria-label={locale.pages.profile.title}
        >
          <ChevronLeft fontSize="large" />
        </IconButton>
      </Box>
      <Typography variant="h1">{LL.title}</Typography>
      <Form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={control}
          rules={{ required: LL.name.errors.required }}
          render={({
            field: { onChange, ...field },
            fieldState: { error, isTouched },
          }) => (
            <TextField
              {...field}
              label={LL.name.label}
              variant="outlined"
              margin="dense"
              fullWidth
              disabled={isSubmitting}
              error={!!error && isTouched}
              helperText={(isTouched && error?.message) || LL.name.helperText}
              onChange={(event) => {
                setValue(
                  'domain',
                  slugify(event.target.value, {
                    replacement: '',
                    strict: true,
                    lower: true,
                  }),
                  { shouldValidate: true }
                );
                onChange(event);
              }}
            />
          )}
        />
        <Controller
          name="domain"
          control={control}
          rules={{
            required: LL.domain.errors.required,
            pattern: {
              value: alphanumericRegex,
              message: LL.domain.errors.alphanumericValidation,
            },
          }}
          render={({ field, fieldState: { isTouched, error } }) => (
            <TextField
              {...field}
              label={LL.domain.label}
              variant="outlined"
              margin="dense"
              fullWidth
              disabled={isSubmitting}
              error={!!error && isTouched}
              helperText={(isTouched && error?.message) || LL.domain.helperText}
              InputLabelProps={{
                shrink: getValues('domain') ? true : undefined,
              }}
            />
          )}
        />
        <LegalCheckbox
          name="legal"
          control={control}
          disabled={isSubmitting}
          document="ola"
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          loading={loading}
          disabled={!isValid}
        >
          {LL.submitButton}
        </LoadingButton>
      </Form>
      <Box textAlign="center">
        <Typography component="small" variant="body2" color="text.secondary">
          {LL.alreadyHaveOrg}{' '}
          <MuiLink component={Link} to="../join-organization" color="inherit">
            {LL.joinOrganizationLink}
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
}
