import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Alert,
  Collapse,
  Box,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import type {
  ResendEmailVerificationLinkMutation,
  ResendEmailVerificationLinkMutationVariables,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from '../../generated/graphql';
import { gql, useMutation } from '@apollo/client';
import locale from '../../locale';
import { emailFormPattern } from '../../utils';
import { Page } from '@evoko/components';
import { PageLogoHeader } from '../../components/PageLogoHeader';
import PageContent from '../../components/PageContent';

const M_VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token) {
      id
      name
      verified
    }
  }
`;

const M_RESEND_EMAIL_VERIFICATION_LINK = gql`
  mutation ResendEmailVerificationLink($email: String!) {
    resendEmailVerificationLink(email: $email)
  }
`;

type ResendEmailVerificationData = {
  email: string;
};

const LL = locale.pages.verifyEmail;

export default function VerifyEmailPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isExpired, setIsExpired] = useState(false);
  const [isResendVerificationLinkSent, setIsResendVerificationLinkSent] =
    useState(false);
  const token = params.get('token') || '';
  const email = params.get('email') || '';

  const [verifyEmail, { loading }] = useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(M_VERIFY_EMAIL, {
    onCompleted: (data) => {
      setErrorMessage(undefined);
      setVerified(data?.verifyEmail.verified);
    },
    onError: (err) => {
      if (err.message === 'Token has expired') {
        setIsExpired(true);
      } else {
        setErrorMessage(LL.alerts.verificationError);
      }
    },
  });

  const [resendVerificationLink] = useMutation<
    ResendEmailVerificationLinkMutation,
    ResendEmailVerificationLinkMutationVariables
  >(M_RESEND_EMAIL_VERIFICATION_LINK, {
    onCompleted: (data) => {
      setIsResendVerificationLinkSent(data?.resendEmailVerificationLink);
    },
    onError: () => {
      setErrorMessage(LL.alerts.resendVerificationLinkError);
    },
  });

  useEffect(() => {
    if (token) {
      verifyEmail({ variables: { token: token } });
    }
  }, [token, verifyEmail]);

  const { handleSubmit, control, formState, watch } =
    useForm<ResendEmailVerificationData>({
      mode: 'onChange',
      defaultValues: {
        email,
      },
    });
  const { isValid } = formState;
  const emailInput = watch('email');

  const onSubmit = async (data: ResendEmailVerificationData) => {
    setErrorMessage(undefined);

    await resendVerificationLink({
      variables: {
        email: data.email,
      },
    });
  };

  const getHeading = (): string => {
    if (verified) {
      return LL.verified;
    } else if (errorMessage === LL.alerts.verificationError) {
      return LL.failure;
    } else if (errorMessage === LL.alerts.resendVerificationLinkError) {
      return LL.resetFailure(emailInput);
    } else if (isResendVerificationLinkSent) {
      return LL.verifyResent(emailInput);
    } else if (isExpired) {
      return LL.expired;
    }
    return LL.heading;
  };

  return (
    <Page title={LL.heading} loading={loading} minHeight="100%">
      <PageLogoHeader />
      <PageContent component="main" maxWidth={410}>
        {!loading && (
          <>
            <Typography variant="h1" align="center">
              {getHeading()}
            </Typography>

            {isResendVerificationLinkSent || !!errorMessage ? null : (
              <Box>
                {!verified && (
                  <Controller
                    name="email"
                    control={control}
                    rules={emailFormPattern}
                    render={({
                      field,
                      fieldState: { isTouched, error },
                      formState: { isSubmitting },
                    }) => (
                      <TextField
                        {...field}
                        type="email"
                        label={LL.form.email.label}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        disabled={isSubmitting}
                        error={!!error && isTouched}
                        helperText={(isTouched && error?.message) || ' '}
                      />
                    )}
                  />
                )}
                <Collapse in={!!errorMessage} unmountOnExit>
                  <Alert severity="error">{errorMessage}</Alert>
                </Collapse>
                <Button
                  fullWidth
                  sx={{ mt: 2 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={
                    verified
                      ? () => navigate('/profile')
                      : handleSubmit(onSubmit)
                  }
                  disabled={!isValid && !verified}
                >
                  {verified
                    ? LL.buttons.login
                    : LL.buttons.resendVerificationEmail}
                </Button>
              </Box>
            )}
          </>
        )}
      </PageContent>
    </Page>
  );
}
