import { useMutation, gql } from '@apollo/client';
import {
  OrganizationCard,
  OrganizationCardActions,
  OrganizationCardDescription,
  OrganizationCardTitle,
} from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { M_JOIN_ORGANIZATION } from '../../../../../graphql';
import { Q_PROFILE } from '../../../../../hooks/useProfileQuery';
import {
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables,
  InvitationFieldsFragment,
  InvitationStatus,
  JoinOrganizationMutation,
  JoinOrganizationMutationVariables,
} from '../../../../../generated/graphql';
import locale from '../../../../../locale';

const LL = locale.pages.profile.organizationList.organizationCard;

const M_DECLINE_INVITATION = gql`
  mutation DeclineInvitation($domain: String!) {
    declineInvitation(domain: $domain) {
      orgId
      email
    }
  }
`;

type InvitationOrganizationCardActionsProps = {
  domain: string;
  disabled: boolean;
};

function InvitationOrganizationCardActions({
  domain,
  disabled,
}: InvitationOrganizationCardActionsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [joinOrganization, { loading: joinLoading }] = useMutation<
    JoinOrganizationMutation,
    JoinOrganizationMutationVariables
  >(M_JOIN_ORGANIZATION, {
    refetchQueries: [Q_PROFILE],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(LL.alerts.JoinOrganizationMutation.success, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(LL.alerts.JoinOrganizationMutation.failure, {
        variant: 'error',
      });
    },
  });

  const [declineInvitation, { loading: declineLoading }] = useMutation<
    DeclineInvitationMutation,
    DeclineInvitationMutationVariables
  >(M_DECLINE_INVITATION, {
    refetchQueries: [Q_PROFILE],
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(LL.alerts.DeclineInvitationMutation.success, {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(LL.alerts.DeclineInvitationMutation.failure, {
        variant: 'error',
      });
    },
  });

  return (
    <OrganizationCardActions>
      <LoadingButton
        disabled={!disabled || declineLoading}
        loading={joinLoading}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => joinOrganization({ variables: { domain } })}
      >
        {LL.joinButton}
      </LoadingButton>
      <LoadingButton
        disabled={!disabled || joinLoading}
        loading={declineLoading}
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => declineInvitation({ variables: { domain } })}
      >
        {LL.declineButton}
      </LoadingButton>
    </OrganizationCardActions>
  );
}

type InvitationOrganizationCardProps = {
  invitation: InvitationFieldsFragment;
  verified: boolean;
};

export function InvitationOrganizationCard({
  invitation,
  verified,
}: InvitationOrganizationCardProps) {
  const expired = invitation.status === InvitationStatus.Expired;

  return (
    <OrganizationCard
      key={invitation.orgId}
      logoUrl={invitation.orgLogoUrl ?? undefined}
      logoDimmed={!verified}
    >
      <OrganizationCardTitle>{invitation.orgName}</OrganizationCardTitle>
      {(expired || !verified) && (
        <OrganizationCardDescription color="warning.main">
          {!verified ? LL.verifyEmail : LL.expired}
        </OrganizationCardDescription>
      )}
      {!expired && (
        <InvitationOrganizationCardActions
          domain={invitation.orgDomain}
          disabled={verified}
        />
      )}
    </OrganizationCard>
  );
}
