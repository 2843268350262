import { useState, forwardRef } from 'react';
import locale from '../locale';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const LL = locale.passwordTextField;

export const passwordRules = {
  minLength: { value: 8, message: LL.errors.minLength },
  validate: {
    lowerCase: (value: string) => /[a-z]/.test(value) || LL.errors.lowerCase,
    upperCase: (value: string) => /[A-Z]/.test(value) || LL.errors.upperCase,
    digit: (value: string) => /\d/.test(value) || LL.errors.digit,
    specialChar: (value: string) =>
      !/^[ a-zA-Z0-9]*$/.test(value) || LL.errors.specialChar,
  },
};

export const PasswordTextField = forwardRef(function PasswordTextField(
  { disabled, ...props }: TextFieldProps,
  ref
) {
  const [showPassword, setShowPassword] = useState(false);

  const PasswordIcon = (
    <InputAdornment position="end">
      <IconButton
        disabled={disabled}
        aria-label="toggle password visibility"
        onClick={() => setShowPassword((prev) => !prev)}
        onMouseDown={(e) => e.preventDefault()}
        edge="end"
        size="large"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{ endAdornment: PasswordIcon }}
      disabled={disabled}
      inputRef={ref}
      {...props}
    />
  );
});
