export const GoogleLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.52 12.2729C23.52 11.422 23.4436 10.6038 23.3018 9.81836H12V14.4602H18.4582C18.18 15.9602 17.3345 17.2311 16.0636 18.082V21.0929H19.9418C22.2109 19.0038 23.52 15.9275 23.52 12.2729Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 23.9998C15.2399 23.9998 17.9562 22.9252 19.9417 21.0925L16.0635 18.0816C14.989 18.8016 13.6144 19.2271 11.9999 19.2271C8.87443 19.2271 6.22897 17.1161 5.28534 14.2798H1.27625V17.3889C3.25079 21.3107 7.30897 23.9998 11.9999 23.9998Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28545 14.2799C5.04545 13.5599 4.90909 12.7908 4.90909 11.9999C4.90909 11.209 5.04545 10.4399 5.28545 9.71993V6.61084H1.27636C0.463636 8.23084 0 10.0636 0 11.9999C0 13.9363 0.463636 15.769 1.27636 17.389L5.28545 14.2799Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 4.77273C13.7617 4.77273 15.3435 5.37818 16.5872 6.56727L20.029 3.12545C17.9508 1.18909 15.2344 0 11.9999 0C7.30897 0 3.25079 2.68909 1.27625 6.61091L5.28534 9.72C6.22897 6.88364 8.87443 4.77273 11.9999 4.77273Z"
      fill="#EA4335"
    />
  </svg>
);
