export const BiampLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 128"
    fill="none"
    {...props}
  >
    <path
      d="M69.4337 63.6119C69.4337 82.6931 55.0891 97.4416 37.2198 97.4416C29.9089 97.4416 23.8178 95.5485 18.8119 92.301V96.0871H0V10.6851L18.8119 0V35.0495C23.8178 31.802 29.9168 29.9089 37.2198 29.9089C55.0812 29.9089 69.4337 44.8 69.4337 63.6119ZM50.8911 63.6119C50.8911 54.1386 43.8495 45.8772 34.1069 45.8772C28.5624 45.8772 23.0099 47.7703 18.8119 53.3228V74.0277C23.0099 79.7148 28.5624 81.6079 34.1069 81.6079C43.8495 81.6079 50.8911 73.2119 50.8911 63.604V63.6119Z"
      fill="currentColor"
    />
    <path
      d="M99.0892 31.2637V96.0954H80.2773V31.2637H99.0892Z"
      fill="currentColor"
    />
    <path
      d="M179.082 31.2626V96.0942H160.27V92.3081C155.264 95.5556 149.173 97.4487 141.862 97.4487C123.993 97.4487 109.514 82.5655 109.514 63.7457C109.514 44.9259 123.993 29.916 141.862 29.916C149.173 29.916 155.264 31.8091 160.27 35.0566V31.2705H179.082V31.2626ZM160.27 74.0269V53.322C156.072 47.6348 150.528 45.7418 144.975 45.7418C135.098 45.7418 128.191 54.1299 128.191 63.7378C128.191 73.3457 135.098 81.4724 144.975 81.4724C150.528 81.4724 156.072 79.5794 160.27 74.0269Z"
      fill="currentColor"
    />
    <path
      d="M300.229 60.4998V96.0958H281.417V62.1236C281.417 51.8345 276.403 46.2899 267.611 46.2899C263.683 46.2899 259.35 48.0483 255.429 53.4661C255.698 55.7632 255.976 58.0681 255.976 60.4998V96.0958H237.164V62.1236C237.164 51.8345 232.15 46.2899 223.223 46.2899C219.572 46.2899 215.508 48.4523 211.714 53.5929V96.0958H192.902V31.2642H211.714V35.1849C215.778 31.3988 220.102 29.2285 227.69 29.2285C235.944 29.2285 243.255 32.476 248.395 38.4325C255.302 32.0721 261.797 29.2285 271.952 29.2285C287.786 29.2285 300.237 41.5453 300.237 60.4998H300.229Z"
      fill="currentColor"
    />
    <path
      d="M380.373 63.7458C380.373 82.5577 366.029 97.4488 348.159 97.4488C340.848 97.4488 334.765 95.5557 329.751 92.3082V116.673L310.939 127.358V31.2627H329.751V35.0488C334.765 31.8013 340.848 29.9082 348.159 29.9082C366.021 29.9082 380.373 44.6567 380.373 63.7379V63.7458ZM361.831 63.7458C361.831 54.1379 354.789 45.7498 345.046 45.7498C339.494 45.7498 333.949 47.6429 329.751 53.33V74.0349C333.949 79.5874 339.494 81.4805 345.046 81.4805C354.789 81.4805 361.831 73.227 361.831 63.7458Z"
      fill="currentColor"
    />
    <path
      d="M391.01 97.4897C395.97 97.4897 399.992 93.4683 399.992 88.5076C399.992 83.5468 395.97 79.5254 391.01 79.5254C386.049 79.5254 382.027 83.5468 382.027 88.5076C382.027 93.4683 386.049 97.4897 391.01 97.4897Z"
      fill="currentColor"
    />
    <path
      d="M378.789 30.7085H376.666V30.3125H381.355V30.7085H379.232V36.0392H378.781V30.7085H378.789Z"
      fill="currentColor"
    />
    <path
      d="M382.732 30.3203H383.16L385.6 33.7025L388.039 30.3203H388.467V36.0391H388.016V31.0728L385.608 34.3678H385.576L383.168 31.0728V36.0391H382.732V30.3203Z"
      fill="currentColor"
    />
  </svg>
);
