import { Control, Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import locale from '../../../../../locale';
import { LegalDialog } from '@evoko/components';
import type { LegalDocumentType } from '@evoko/api';
import { useState } from 'react';
import { Link } from '@mui/material';

type Props = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  disabled?: boolean;
  document: LegalDocumentType;
};

const LL = locale.pages.register;

export const LegalCheckbox = ({
  name,
  control,
  disabled = false,
  document,
}: Props) => {
  const [isLegalDialogOpen, setIsLegalDialogOpen] = useState(false);

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        rules={{ required: true }}
        render={({ field: { onChange, value, ...field } }) => (
          <FormControlLabel
            label={
              <Typography
                variant="body2"
                component="span"
                color="text.secondary"
              >
                {LL.form.legalLink.text}
                <Link
                  color="inherit"
                  component="button"
                  type="button"
                  onClick={() => setIsLegalDialogOpen(true)}
                >
                  {LL.form.legalLink.link}
                </Link>
              </Typography>
            }
            disabled={disabled}
            sx={{ ml: 0, mb: 1 }}
            control={
              <Checkbox
                {...field}
                color="primary"
                checked={value}
                onChange={(_, checked) => onChange(checked)}
              />
            }
          />
        )}
      />
      <LegalDialog
        variant="readonly"
        document={document}
        open={isLegalDialogOpen}
        onClose={() => setIsLegalDialogOpen(false)}
      />
    </>
  );
};
