import Button, { ButtonProps } from '@mui/material/Button';

export const SignInButton = ({ children, ...props }: ButtonProps) => (
  <Button
    variant="contained"
    color="secondary"
    size="large"
    sx={{
      backgroundColor: 'grey.800',
      color: 'white',
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);
