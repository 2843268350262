import { ApolloError } from '@apollo/client';
import { ErrorCode, useAuth, useReturnTo } from '@evoko/api';
import {
  AppleLogo,
  GoogleLogo,
  MicrosoftLogo,
  Page,
  PasswordTextField,
} from '@evoko/components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import PageContent from '../../components/PageContent';
import { PageLogoHeader } from '../../components/PageLogoHeader';
import { SignInButton } from '../../components/SignInButton';
import locale from '../../locale';
import { emailFormPattern } from '../../utils';
import type { RegisterLocationState } from '../RegisterPage';
import useSavedCredentials from './hooks/useSavedCredentials';

export type SignInLocationState = {
  email?: string;
  showForm?: boolean;
  successMessage?: string;
};

const LL = locale.pages.signIn;

export default function SignInPage() {
  const location = useLocation();
  const locationState = location?.state as SignInLocationState | undefined;
  const loginHint = locationState?.email;
  const registerState: RegisterLocationState = { email: loginHint };
  const { returnTo, returnToSearch } = useReturnTo();
  const [credentials, setCredentials, clearCredentials] = useSavedCredentials();
  const [showForm, setShowForm] = useState(!!locationState?.showForm);
  const {
    loading,
    error,
    clearError,
    signInWithEmail,
    signInWithGoogle,
    signInWithMicrosoft,
    signInWithApple,
  } = useAuth();

  const { control, handleSubmit, formState, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: locationState?.email ?? '',
      password: '',
      rememberMe: false,
    },
  });
  const { isValid, isDirty } = formState;

  useEffect(() => {
    if (credentials.email) {
      setValue('email', credentials.email, { shouldValidate: true });
      setValue('rememberMe', true);
    }
  }, [setValue, credentials.email, showForm]);

  useEffect(() => {
    if (locationState?.email) {
      setValue('email', locationState.email, { shouldValidate: true });
    }
  }, [setValue, locationState, showForm]);

  // Clear any auth error on unmount
  useEffect(() => {
    return () => clearError();
  }, [clearError]);

  const alertError = useMemo(() => {
    if (error?.type !== 'signin-email' && error?.type !== 'signin-oauth') {
      return;
    }
    if (error.content instanceof ApolloError) {
      for (const { extensions } of error.content.graphQLErrors) {
        switch (extensions?.code) {
          case ErrorCode.NotFound: {
            if (error.type === 'signin-oauth') {
              return LL.oauth.errors.notFound;
            }
            return LL.form.errors.notFound;
          }
        }
      }
    }
    return LL.error;
  }, [error]);

  const onSubmit = handleSubmit(async ({ email, password, rememberMe }) => {
    if (rememberMe) {
      setCredentials({ email });
    }
    await signInWithEmail({ email, password, returnTo });
  });

  return (
    <Page title={LL.header} loading={loading} minHeight="100%">
      <PageLogoHeader />
      <PageContent component="main" maxWidth={410}>
        <Typography variant="h1" align="center">
          {LL.header}
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          <Collapse in={!!locationState?.successMessage} unmountOnExit>
            <Alert severity="success">{locationState?.successMessage}</Alert>
          </Collapse>
          <Collapse in={!!alertError && !showForm} unmountOnExit>
            <Alert severity="error">{alertError}</Alert>
          </Collapse>
        </Box>
        {!showForm ? (
          <>
            <SignInButton
              fullWidth
              disabled={loading}
              onClick={() => signInWithGoogle({ loginHint, returnTo })}
              startIcon={<GoogleLogo aria-hidden />}
            >
              {LL.googleButton}
            </SignInButton>
            <SignInButton
              fullWidth
              disabled={loading}
              onClick={() => signInWithMicrosoft({ loginHint, returnTo })}
              startIcon={<MicrosoftLogo aria-hidden />}
            >
              {LL.microsoftButton}
            </SignInButton>
            <SignInButton
              fullWidth
              disabled={loading}
              onClick={() => signInWithApple({ returnTo })}
              startIcon={<AppleLogo aria-hidden />}
            >
              {LL.appleButton}
            </SignInButton>
            <Divider>{LL.or}</Divider>
            <SignInButton
              fullWidth
              disabled={loading}
              onClick={() => {
                setShowForm(true);
                clearError();
              }}
            >
              {LL.emailButton}
            </SignInButton>
          </>
        ) : (
          <>
            <Box padding={3} position="absolute" left={0} top={0}>
              <IconButton
                onClick={() => {
                  setShowForm(false);
                  clearError();
                  reset();
                }}
                aria-label="choose another sign in method"
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box
              component="form"
              onSubmit={onSubmit}
              display="flex"
              flexDirection="column"
            >
              <Controller
                name="email"
                control={control}
                rules={emailFormPattern}
                render={({
                  field,
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <TextField
                    {...field}
                    sx={{ mb: 1 }}
                    type="email"
                    label={LL.form.email.label}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={!!error && (isTouched || isSubmitted)}
                    helperText={
                      ((isTouched || isSubmitted) && error?.message) || ' '
                    }
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: LL.form.password.required }}
                render={({
                  field,
                  fieldState: { isTouched, error },
                  formState: { isSubmitted },
                }) => (
                  <PasswordTextField
                    {...field}
                    sx={{ mb: 1 }}
                    label={LL.form.password.label}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={!!error && (isTouched || isSubmitted)}
                    helperText={
                      ((isTouched || isSubmitted) && error?.message) || ' '
                    }
                  />
                )}
              />
              <Box display="flex" alignItems="center" justifyContent="center">
                <Controller
                  control={control}
                  name="rememberMe"
                  render={({ field: { value, onChange, ...field } }) => (
                    <FormControlLabel
                      label={LL.form.rememberMe.label}
                      disabled={loading}
                      control={
                        <Checkbox
                          {...field}
                          color="primary"
                          checked={!!value}
                          onChange={(_, checked) => {
                            if (!checked) {
                              clearCredentials();
                            }
                            onChange(checked);
                          }}
                        />
                      }
                    />
                  )}
                />
              </Box>
              <Collapse in={!!alertError} unmountOnExit>
                <Alert severity="error" sx={{ mt: 1 }}>
                  {alertError}
                </Alert>
              </Collapse>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 2 }}
                disabled={!isValid || !isDirty || loading}
                type="submit"
              >
                {LL.submitButton}
              </Button>
            </Box>
          </>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={0.5}
        >
          {showForm && (
            <MuiLink
              component={Link}
              variant="body2"
              color="inherit"
              to={{
                pathname: '/forgot-password',
                search: returnToSearch?.toString(),
              }}
              align="center"
            >
              {LL.forgotPassword}
            </MuiLink>
          )}
          <Typography variant="body2" align="center">
            {LL.registerLink.text}{' '}
            <MuiLink
              color="inherit"
              component={Link}
              to={{ pathname: '/register', search: returnToSearch?.toString() }}
              state={registerState}
            >
              {LL.registerLink.link}
            </MuiLink>
          </Typography>
        </Box>
      </PageContent>
    </Page>
  );
}
